import { ReactElement } from 'react';

import { CrossbarParticipationWinner } from 'crossbar/types/crossbar-participation.model';
import { UserPlayer } from 'common/models/player.model';

interface Props {
  winner: CrossbarParticipationWinner;
}

export default function CrossbarWinner({ winner }: Props): ReactElement {
  const { email, firstName, lastName, phoneNumber } = winner.player as UserPlayer;

  return (
    <div>
      <div><strong>Prénom</strong> : {firstName}</div>
      <div><strong>Nom</strong> : {lastName}</div>
      <div><strong>Email</strong> : {email}</div>
      <div><strong>Téléphone</strong> : {phoneNumber}</div>
    </div>
  );
}
