import { Route, Routes } from "react-router-dom"

import {
  CLICK_AND_COLLECT_PATH,
  CROSSBAR_PATH,
  MAN_OF_THE_MATCH_PATH,
  PRONOSTIC_PATH,
  QUIZ_PATH,
  WALL_PATH,
} from "_urls"

import { ClickAndCollectPDFPresenter } from "click-and-collect/views/ClickAndCollectPDFPresenter"
import { CrossbarRoutes } from "crossbar/routes/CrossbarRoutes"
import { EventPlayThemeWrapper } from "event/play/components/EventPlayThemeWrapper"
import { EventHomepageView } from "event/play/views/EventHomepageView"
import { PronosticView } from "event/play/views/PronosticView"
import ManOfTheMatchView from "event/play/views/ManOfTheMatchView"
import { WallPlayRoutes } from "wall/routes/WallPlayRoutes"
import { QuizGamePlayRoutes } from "quiz/routes/QuizGamePlayRoutes"

export function EventPlayRoutes() {
  return (
    <Routes>
      <Route path=":eventId" element={<EventPlayThemeWrapper />}>
        <Route path="" element={<EventHomepageView />} />
        <Route path={PRONOSTIC_PATH} element={<PronosticView />} />
        <Route path={MAN_OF_THE_MATCH_PATH} element={<ManOfTheMatchView />} />
        <Route
          path={CLICK_AND_COLLECT_PATH}
          element={<ClickAndCollectPDFPresenter />}
        />
        <Route path={`${CROSSBAR_PATH}/*`} element={<CrossbarRoutes />} />
        <Route path={`${WALL_PATH}/*`} element={<WallPlayRoutes />} />
        <Route path={`${QUIZ_PATH}/*`} element={<QuizGamePlayRoutes />} />
      </Route>
    </Routes>
  )
}
