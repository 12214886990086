import { Routes, Route } from "react-router-dom"
import { ClickAndCollectAdminListView } from "click-and-collect/views/ClickAndCollectAdminListView"

export function ClickAndCollectAdminRoutes() {
  return (
    <Routes>
      <Route path="" element={<ClickAndCollectAdminListView />} />
    </Routes>
  )
}
