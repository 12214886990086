import { Anchor, Breadcrumbs } from "@mantine/core"
import { Link } from "react-router-dom"

import { ADMIN_NAV_EVENTS } from "_constants"
import type { Event } from "event/types/event.model"

function EventBreadcrumbs({ event }: { event?: Event | "new" }) {
  return (
    <Breadcrumbs separator="→">
      <Anchor component={Link} to={"/admin"}>
        Admin
      </Anchor>
      {event ? (
        <Anchor component={Link} to={"/admin/events"}>
          {ADMIN_NAV_EVENTS}
        </Anchor>
      ) : (
        <span>{ADMIN_NAV_EVENTS}</span>
      )}
      {event === "new" && <span>Nouvel événement</span>}
    </Breadcrumbs>
  )
}

export default EventBreadcrumbs
