import { Text } from '@mantine/core';
import { ReactElement } from 'react';

import { Crossbar } from 'crossbar/types/crossbar.model';
import { CrossbarParticipationStatus } from 'crossbar/types/crossbar-participation.model';

interface Props {
  crossbar: Crossbar;
  status: CrossbarParticipationStatus;
}

export default function Result({ crossbar, status }: Props): ReactElement {
  const { messages: { looser_msg, winner_msg } } = crossbar;

  let message = '';
  switch (status) {
    case 'goal':
      message = winner_msg;
      break;
    case 'missed':
      message = looser_msg;
      break;
  }

  return (
    <Text
      className="text-3xl text-center font-team-a-bold"
      sx={{
        color: "#002951",
      }}
      tt="uppercase"
      dangerouslySetInnerHTML={{ __html: message }}
    />
  );
}
