import { Route, Routes } from "react-router-dom"

import { ADMIN_GAMES_PATH } from "_urls"
import { QuizAdminView } from "quiz/admin/views/QuizAdminView"
import { QuizGameAdminPresenter } from "quiz/admin/views/QuizGameAdminPresenter"
import { QuizzesListAdminView } from "quiz/admin/views/QuizzesListAdminView"

export function QuizAdminRoutes() {
  return (
    <Routes>
      <Route path="" element={<QuizzesListAdminView />} />
      <Route path="new" element={<QuizAdminView />} />
      <Route path=":quizId">
        <Route path="" element={<QuizAdminView />} />
        <Route
          path={`${ADMIN_GAMES_PATH}/:gameId`}
          element={<QuizGameAdminPresenter />}
        />
      </Route>
    </Routes>
  )
}
