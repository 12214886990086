import { Link } from "react-router-dom"
import { Anchor, Breadcrumbs } from "@mantine/core"

import { ADMIN_NAV_CROSSBAR } from "_constants"
import { Crossbar } from "crossbar/types/crossbar.model"

export function CrossbarBreadcrumbs({ crossbar }: { crossbar?: Crossbar }) {
  return (
    <Breadcrumbs separator="→">
      <Anchor component={Link} to={"/admin"}>
        Admin
      </Anchor>
      {crossbar ? (
        <Anchor component={Link} to={"/admin/crossbars"}>
          {ADMIN_NAV_CROSSBAR}
        </Anchor>
      ) : (
        <span>{ADMIN_NAV_CROSSBAR}</span>
      )}
    </Breadcrumbs>
  )
}
