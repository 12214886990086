import { useCallback, useEffect, useState } from "react"
import { Box, Button, Group, Loader, Stack, Table, Title } from "@mantine/core"
import { v4 as uuidv4 } from "uuid"

import {
  ADMIN_ORGANIZATION,
  CLICK_AND_COLLECT_CREATE_AUTHORISED_ORGANIZATIONS,
  CLICK_AND_COLLECT_CREATE_AUTHORISED_ROLES,
  CLICK_AND_COLLECT_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS,
  CLICK_AND_COLLECT_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES,
} from "_roles"
import { AccessRights } from "common/admin/auth/AccessRights"
import { ClickAndCollectBreadcrumbs } from "../components/ClickAndCollectBreadcrumbs"
import { useListFoodMenusQuery } from "click-and-collect/api/foodMenu.api"
import { useOrganization } from "common/admin/auth/hooks"

import type {
  FoodMenu,
  FoodMenuFormMode,
} from "click-and-collect/types/foodMenu.model"
import { FoodMenuForm } from "../components/FoodMenuForm"

export function ClickAndCollectAdminListView() {
  const [organization] = useOrganization()

  const [foodMenuFormMode, setFoodMenuFormMode] =
    useState<FoodMenuFormMode>("read")

  const setReadOnly = () => setFoodMenuFormMode("read")

  const {
    isLoading,
    isFetching,

    isError,
    error,

    refetch,
    data: foodMenus,
  } = useListFoodMenusQuery()

  const ths = (
    <tr>
      <th>Intitulé</th>
      <AccessRights
        authorizedOrganizations={
          CLICK_AND_COLLECT_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS
        }
        authorizedRoles={
          CLICK_AND_COLLECT_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES
        }
      >
        <th>Organization</th>
      </AccessRights>

      <th>Date</th>
      <th>Document</th>
      <th>Actions</th>
    </tr>
  )

  const rows = useCallback(() => {
    let filledRows: JSX.Element[] = []
    if (organization) {
      filledRows =
        foodMenus && foodMenus.length > 0
          ? foodMenus
              .filter(
                (menu: FoodMenu) =>
                  organization === ADMIN_ORGANIZATION ||
                  menu.organization === organization
              )
              .map((foodMenu: FoodMenu) => {
                return (
                  <FoodMenuForm
                    key={uuidv4()}
                    foodMenu={foodMenu}
                    initialMode="read"
                    organization={organization}
                    setReadOnly={setReadOnly}
                    refetch={refetch}
                  />
                )
              })
          : []

      if (foodMenuFormMode === "creation") {
        const newFoodMenuForm = (
          <FoodMenuForm
            key={uuidv4()}
            initialMode="creation"
            organization={organization}
            setReadOnly={setReadOnly}
            refetch={refetch}
          />
        )
        filledRows.push(newFoodMenuForm)
      }
    }
    return filledRows
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foodMenus, foodMenuFormMode, organization])

  useEffect(() => {
    if (isError) {
      console.error("Get FoodMenu list error", JSON.stringify(error))
    }
  }, [isError, error])

  return (
    <Box>
      <ClickAndCollectBreadcrumbs />
      <Title order={3} align={"center"} m={"lg"}>
        Liste des menus
      </Title>

      <AccessRights
        authorizedRoles={CLICK_AND_COLLECT_CREATE_AUTHORISED_ROLES}
        authorizedOrganizations={
          CLICK_AND_COLLECT_CREATE_AUTHORISED_ORGANIZATIONS
        }
      >
        <Group position={"right"}>
          <Button
            className="bg-button-admin"
            onClick={() => setFoodMenuFormMode("creation")}
          >
            Ajouter un menu
          </Button>
        </Group>
      </AccessRights>
      {isFetching || isLoading ? (
        <Stack align={"center"}>
          <Loader />
        </Stack>
      ) : null}
      {!isFetching ? (
        <Table highlightOnHover>
          <thead>{ths}</thead>
          <tbody>{rows()}</tbody>
          <tfoot></tfoot>
        </Table>
      ) : null}
    </Box>
  )
}
