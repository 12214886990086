import { Text } from '@mantine/core';
import { ReactElement } from 'react';

interface Props {
  name: string;
  onSelect: () => void;
  photo: string;
  selected: boolean;
}

export default function Goalkeeper({ name, onSelect, photo, selected }: Props): ReactElement {
    return (
      <div style={{
        borderColor: selected ? '#e30613' : 'transparent',
        borderWidth: 2,
        borderStyle: 'solid',
        boxSizing: 'border-box',
        padding: 10,
        width: '50%',
      }}>
        <img
          alt={name}
          onClick={onSelect}
          src={photo}
          style={{ cursor: 'pointer' }}
        />

        <Text
          className="text-xl text-center font-team-a-bold"
          sx={{
            color: "#ffffff",
          }}
          mt={20}
          dangerouslySetInnerHTML={{ __html: name }}
        />
      </div>
    );
}
