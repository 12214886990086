import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { isEqual } from "lodash"
import {
  Button,
  Group,
  LoadingOverlay,
  Select,
  Stack,
  TextInput,
} from "@mantine/core"
import { z } from "zod"
import { zodResolver } from "mantine-form-zod-resolver"

import {
  CROSSBAR_DEFAULT_BACKGROUND,
  CROSSBAR_DEFAULT_GOALKEEPER_CHOICE_MSG,
  CROSSBAR_DEFAULT_GOALKEEPER_LEFT_GOAL_DOWN_LEFT_VIDEO,
  CROSSBAR_DEFAULT_GOALKEEPER_LEFT_GOAL_DOWN_RIGHT_VIDEO,
  CROSSBAR_DEFAULT_GOALKEEPER_LEFT_GOAL_UPPER_LEFT_VIDEO,
  CROSSBAR_DEFAULT_GOALKEEPER_LEFT_GOAL_UPPER_RIGHT_VIDEO,
  CROSSBAR_DEFAULT_GOALKEEPER_LEFT_INTRO_VIDEO,
  CROSSBAR_DEFAULT_GOALKEEPER_LEFT_MISSED_DOWN_LEFT_VIDEO,
  CROSSBAR_DEFAULT_GOALKEEPER_LEFT_MISSED_DOWN_RIGHT_VIDEO,
  CROSSBAR_DEFAULT_GOALKEEPER_LEFT_MISSED_UPPER_LEFT_VIDEO,
  CROSSBAR_DEFAULT_GOALKEEPER_LEFT_MISSED_UPPER_RIGHT_VIDEO,
  CROSSBAR_DEFAULT_GOALKEEPER_LEFT_PHOTO,
  CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_GOAL_DOWN_LEFT_VIDEO,
  CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_GOAL_DOWN_RIGHT_VIDEO,
  CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_GOAL_UPPER_LEFT_VIDEO,
  CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_GOAL_UPPER_RIGHT_VIDEO,
  CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_INTRO_VIDEO,
  CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_MISSED_DOWN_LEFT_VIDEO,
  CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_MISSED_DOWN_RIGHT_VIDEO,
  CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_MISSED_UPPER_LEFT_VIDEO,
  CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_MISSED_UPPER_RIGHT_VIDEO,
  CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_PHOTO,
  CROSSBAR_DEFAULT_LOOSER_EXPLANATION_MSG,
  CROSSBAR_DEFAULT_LOOSER_MSG,
  CROSSBAR_DEFAULT_TRY_AGAIN_MSG,
  CROSSBAR_DEFAULT_WAITING_MSG,
  CROSSBAR_DEFAULT_WELCOME_CONTENT_MSG,
  CROSSBAR_DEFAULT_WELCOME_FOOTER_MSG,
  CROSSBAR_DEFAULT_WELCOME_HEADER_MSG,
  CROSSBAR_DEFAULT_WINNER_EXPLANATION_MSG,
  CROSSBAR_DEFAULT_WINNER_MSG,
} from "_constants"
import {
  CROSSBAR_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS,
  CROSSBAR_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES,
  ORGANIZATIONS,
} from "_roles"

import { AccessRights } from "common/admin/auth/AccessRights"
import { useOrganization } from "common/admin/auth/hooks"

import type { Crossbar } from "crossbar/types/crossbar.model"
import {
  CrossbarFormProvider,
  useCrossbarForm,
} from "crossbar/contexts/crossbar.context"

import {
  useAddCrossbarMutation,
  useUpdateCrossbarMutation,
} from "crossbar/api/crossbar.api"

import Collapsable from "common/admin/ui/Collapsable"
import { useDebug } from "common/debug/hooks/useDebug"
import FileInput from "common/admin/ui/FileInput"

export function CrossbarAdminForm({
  crossbar,
  editMode,
}: {
  crossbar?: Crossbar
  editMode: boolean
}) {
  const { isDebugMode, log } = useDebug()

  const [organization] = useOrganization()
  const navigate = useNavigate()

  const [addCrossbar, addCrossbarResult] = useAddCrossbarMutation()
  const [updateCrossbar, updateCrossbarResult] = useUpdateCrossbarMutation()
  const [isLoading, setIsLoading] = useState(false)
  const { isError: isErrorAddCrossbarResult, error: errorAddCrossbarResult } =
    addCrossbarResult
  const {
    isError: isErrorUpdateCrossbarResult,
    error: errorUpdateCrossbarResult,
  } = updateCrossbarResult

  useEffect(() => {
    if (isDebugMode) {
      if (isErrorAddCrossbarResult) {
        log(`errorAddCrossbarResult: ${JSON.stringify(errorAddCrossbarResult)}`)
      }
      if (isErrorUpdateCrossbarResult) {
        log(
          `errorUpdateCrossbarResult: ${JSON.stringify(
            errorUpdateCrossbarResult
          )}`
        )
      }
    }
  }, [
    isDebugMode,
    isErrorAddCrossbarResult,
    isErrorUpdateCrossbarResult,
    errorAddCrossbarResult,
    errorUpdateCrossbarResult,
    log,
  ])

  const save = async () => {
    setIsLoading(true)
    if (crossbar) {
      try {
        const updateCrossbarPayload = updateCrossbar({
          id: crossbar.id,
          ...form.values,
        })
        log(`Update successful: ${JSON.stringify(updateCrossbarPayload)}`)
      } catch (error) {
        log(`Update error: ${error}`)
      }
      return
    }

    try {
      const addCrossbarPayload = await addCrossbar(form.values).unwrap()
      log(`Add crossbar fullfiled: ${JSON.stringify(addCrossbarPayload)}`)
    } catch (error) {
      log(`Update error: ${error}`)
    }

    setIsLoading(false)
    navigate(`../.`)
  }

  const initialFormValues = useCallback(() => {
    if (crossbar && editMode) {
      return {
        access: {
          entity: crossbar.access?.entity ? crossbar.access.entity : "",
          organization: crossbar.access?.organization
            ? crossbar.access.organization
            : organization
            ? organization
            : "",
          section: crossbar.access?.section ? crossbar?.access.section : "",
        },
        creationTime: new Date(crossbar.creationTime).toISOString(),
        images: {
          background: crossbar.images?.background
            ? crossbar.images.background
            : "",
        },
        goalkeeper_right: {
          photo: crossbar.goalkeeper_right?.photo
            ? crossbar.goalkeeper_right.photo
            : CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_PHOTO,
          videos: {
            intro: crossbar.goalkeeper_right?.videos?.intro
              ? crossbar.goalkeeper_right.videos.intro
              : CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_INTRO_VIDEO,
            goal_upper_left: crossbar.goalkeeper_right?.videos?.goal_upper_left
              ? crossbar.goalkeeper_right.videos.goal_upper_left
              : CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_GOAL_UPPER_LEFT_VIDEO,
            goal_upper_right: crossbar.goalkeeper_right?.videos
              ?.goal_upper_right
              ? crossbar.goalkeeper_right.videos.goal_upper_right
              : CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_GOAL_UPPER_RIGHT_VIDEO,
            goal_down_left: crossbar.goalkeeper_right?.videos?.goal_down_left
              ? crossbar.goalkeeper_right.videos.goal_down_left
              : CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_GOAL_DOWN_LEFT_VIDEO,
            goal_down_right: crossbar.goalkeeper_right?.videos?.goal_down_right
              ? crossbar.goalkeeper_right.videos.goal_down_right
              : CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_GOAL_DOWN_RIGHT_VIDEO,
            missed_upper_left: crossbar.goalkeeper_right?.videos
              ?.missed_upper_left
              ? crossbar.goalkeeper_right.videos.missed_upper_left
              : CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_MISSED_UPPER_LEFT_VIDEO,
            missed_upper_right: crossbar.goalkeeper_right?.videos
              ?.missed_upper_right
              ? crossbar.goalkeeper_right.videos.missed_upper_right
              : CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_MISSED_UPPER_RIGHT_VIDEO,
            missed_down_left: crossbar.goalkeeper_right?.videos
              ?.missed_down_left
              ? crossbar.goalkeeper_right.videos.missed_down_left
              : CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_MISSED_DOWN_LEFT_VIDEO,
            missed_down_right: crossbar.goalkeeper_right?.videos
              ?.missed_down_right
              ? crossbar.goalkeeper_right.videos.missed_down_right
              : CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_MISSED_DOWN_RIGHT_VIDEO,
          },
        },
        goalkeeper_left: {
          photo: crossbar.goalkeeper_left?.photo
            ? crossbar.goalkeeper_left.photo
            : CROSSBAR_DEFAULT_GOALKEEPER_LEFT_PHOTO,
          videos: {
            intro: crossbar.goalkeeper_left?.videos?.intro
              ? crossbar.goalkeeper_left.videos.intro
              : CROSSBAR_DEFAULT_GOALKEEPER_LEFT_INTRO_VIDEO,
            goal_upper_left: crossbar.goalkeeper_left?.videos?.goal_upper_left
              ? crossbar.goalkeeper_left.videos.goal_upper_left
              : CROSSBAR_DEFAULT_GOALKEEPER_LEFT_GOAL_UPPER_LEFT_VIDEO,
            goal_upper_right: crossbar.goalkeeper_left?.videos?.goal_upper_right
              ? crossbar.goalkeeper_left.videos.goal_upper_right
              : CROSSBAR_DEFAULT_GOALKEEPER_LEFT_GOAL_UPPER_RIGHT_VIDEO,
            goal_down_left: crossbar.goalkeeper_left?.videos?.goal_down_left
              ? crossbar.goalkeeper_left.videos.goal_down_left
              : CROSSBAR_DEFAULT_GOALKEEPER_LEFT_GOAL_DOWN_LEFT_VIDEO,
            goal_down_right: crossbar.goalkeeper_left?.videos?.goal_down_right
              ? crossbar.goalkeeper_left.videos.goal_down_right
              : CROSSBAR_DEFAULT_GOALKEEPER_LEFT_GOAL_DOWN_RIGHT_VIDEO,
            missed_upper_left: crossbar.goalkeeper_left?.videos
              ?.missed_upper_left
              ? crossbar.goalkeeper_left.videos.missed_upper_left
              : CROSSBAR_DEFAULT_GOALKEEPER_LEFT_MISSED_UPPER_LEFT_VIDEO,
            missed_upper_right: crossbar.goalkeeper_left?.videos
              ?.missed_upper_right
              ? crossbar.goalkeeper_left.videos.missed_upper_right
              : CROSSBAR_DEFAULT_GOALKEEPER_LEFT_MISSED_UPPER_RIGHT_VIDEO,
            missed_down_left: crossbar.goalkeeper_left?.videos?.missed_down_left
              ? crossbar.goalkeeper_left.videos.missed_down_left
              : CROSSBAR_DEFAULT_GOALKEEPER_LEFT_MISSED_DOWN_LEFT_VIDEO,
            missed_down_right: crossbar.goalkeeper_left?.videos
              ?.missed_down_right
              ? crossbar.goalkeeper_left.videos.missed_down_right
              : CROSSBAR_DEFAULT_GOALKEEPER_LEFT_MISSED_DOWN_RIGHT_VIDEO,
          },
        },
        messages: {
          goalkeeper_choice_msg: crossbar.messages?.goalkeeper_choice_msg
            ? crossbar.messages.goalkeeper_choice_msg
            : CROSSBAR_DEFAULT_GOALKEEPER_CHOICE_MSG,
          looser_explanation_msg: crossbar.messages?.looser_explanation_msg
            ? crossbar.messages.looser_explanation_msg
            : CROSSBAR_DEFAULT_LOOSER_EXPLANATION_MSG,
          looser_msg: crossbar.messages?.looser_msg
            ? crossbar.messages.looser_msg
            : CROSSBAR_DEFAULT_LOOSER_MSG,
          try_again_msg: crossbar.messages?.try_again_msg
            ? crossbar.messages.try_again_msg
            : CROSSBAR_DEFAULT_TRY_AGAIN_MSG,
          waiting_msg: crossbar.messages?.waiting_msg
            ? crossbar.messages.waiting_msg
            : CROSSBAR_DEFAULT_WAITING_MSG,
          welcome_header_msg: crossbar.messages?.welcome_header_msg
            ? crossbar.messages.welcome_header_msg
            : CROSSBAR_DEFAULT_WELCOME_HEADER_MSG,
          welcome_content_msg: crossbar.messages?.welcome_content_msg
            ? crossbar.messages.welcome_content_msg
            : CROSSBAR_DEFAULT_WELCOME_CONTENT_MSG,
          welcome_footer_msg: crossbar.messages?.welcome_footer_msg
            ? crossbar.messages.welcome_footer_msg
            : CROSSBAR_DEFAULT_WELCOME_FOOTER_MSG,
          winner_explanation_msg: crossbar.messages?.winner_explanation_msg
            ? crossbar.messages.winner_explanation_msg
            : CROSSBAR_DEFAULT_WINNER_EXPLANATION_MSG,
          winner_msg: crossbar.messages?.winner_msg
            ? crossbar.messages.winner_msg
            : CROSSBAR_DEFAULT_WINNER_MSG,
        },
        name: crossbar.name,
      }
    }
    return {
      access: {
        entity: "",
        organization: organization ? organization : "",
        section: "",
      },
      creationTime: new Date().toISOString(),
      images: {
        background: CROSSBAR_DEFAULT_BACKGROUND,
      },
      goalkeeper_right: {
        photo: CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_PHOTO,
        videos: {
          intro: CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_INTRO_VIDEO,
          goal_upper_left:
            CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_GOAL_UPPER_LEFT_VIDEO,
          goal_upper_right:
            CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_GOAL_UPPER_RIGHT_VIDEO,
          goal_down_left:
            CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_GOAL_DOWN_LEFT_VIDEO,
          goal_down_right:
            CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_GOAL_DOWN_RIGHT_VIDEO,
          missed_upper_left:
            CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_MISSED_UPPER_LEFT_VIDEO,
          missed_upper_right:
            CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_MISSED_UPPER_RIGHT_VIDEO,
          missed_down_left:
            CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_MISSED_DOWN_LEFT_VIDEO,
          missed_down_right:
            CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_MISSED_DOWN_RIGHT_VIDEO,
        },
      },
      goalkeeper_left: {
        photo: CROSSBAR_DEFAULT_GOALKEEPER_LEFT_PHOTO,
        videos: {
          intro: CROSSBAR_DEFAULT_GOALKEEPER_LEFT_INTRO_VIDEO,
          goal_upper_left:
            CROSSBAR_DEFAULT_GOALKEEPER_LEFT_GOAL_UPPER_LEFT_VIDEO,
          goal_upper_right:
            CROSSBAR_DEFAULT_GOALKEEPER_LEFT_GOAL_UPPER_RIGHT_VIDEO,
          goal_down_left: CROSSBAR_DEFAULT_GOALKEEPER_LEFT_GOAL_DOWN_LEFT_VIDEO,
          goal_down_right:
            CROSSBAR_DEFAULT_GOALKEEPER_LEFT_GOAL_DOWN_RIGHT_VIDEO,
          missed_upper_left:
            CROSSBAR_DEFAULT_GOALKEEPER_LEFT_MISSED_UPPER_LEFT_VIDEO,
          missed_upper_right:
            CROSSBAR_DEFAULT_GOALKEEPER_LEFT_MISSED_UPPER_RIGHT_VIDEO,
          missed_down_left:
            CROSSBAR_DEFAULT_GOALKEEPER_LEFT_MISSED_DOWN_LEFT_VIDEO,
          missed_down_right:
            CROSSBAR_DEFAULT_GOALKEEPER_LEFT_MISSED_DOWN_RIGHT_VIDEO,
        },
      },
      messages: {
        goalkeeper_choice_msg: CROSSBAR_DEFAULT_GOALKEEPER_CHOICE_MSG,
        looser_explanation_msg: CROSSBAR_DEFAULT_LOOSER_EXPLANATION_MSG,
        looser_msg: CROSSBAR_DEFAULT_LOOSER_MSG,
        try_again_msg: CROSSBAR_DEFAULT_TRY_AGAIN_MSG,
        waiting_msg: CROSSBAR_DEFAULT_WAITING_MSG,
        welcome_header_msg: CROSSBAR_DEFAULT_WELCOME_HEADER_MSG,
        welcome_content_msg: CROSSBAR_DEFAULT_WELCOME_CONTENT_MSG,
        welcome_footer_msg: CROSSBAR_DEFAULT_WELCOME_FOOTER_MSG,
        winner_explanation_msg: CROSSBAR_DEFAULT_WINNER_EXPLANATION_MSG,
        winner_msg: CROSSBAR_DEFAULT_WINNER_MSG,
      },
      name: "",
    }
  }, [crossbar, editMode, organization])

  const schema = z.object({
    access: z.object({
      entity: z.string().optional(),
      organization: z
        .string()
        .trim()
        .min(1, { message: "Organisation requise" }),
      section: z.string().optional(),
    }),
    creationTime: z.string(),
    images: z.object({
      background: z.string(),
    }),
    goalkeeper_right: z.object({
      photo: z.string(),
      videos: z.object({
        intro: z.string(),
        goal_upper_left: z.string(),
        goal_upper_right: z.string(),
        goal_down_left: z.string(),
        goal_down_right: z.string(),
        missed_upper_left: z.string(),
        missed_upper_right: z.string(),
        missed_down_left: z.string(),
        missed_down_right: z.string(),
      }),
    }),
    goalkeeper_left: z.object({
      photo: z.string(),
      videos: z.object({
        intro: z.string(),
        goal_upper_left: z.string(),
        goal_upper_right: z.string(),
        goal_down_left: z.string(),
        goal_down_right: z.string(),
        missed_upper_left: z.string(),
        missed_upper_right: z.string(),
        missed_down_left: z.string(),
        missed_down_right: z.string(),
      }),
    }),
    messages: z.object({
      goalkeeper_choice_msg: z.string(),
      looser_explanation_msg: z.string(),
      looser_msg: z.string(),
      try_again_msg: z.string(),
      waiting_msg: z.string(),
      welcome_header_msg: z.string(),
      welcome_content_msg: z.string(),
      welcome_footer_msg: z.string(),
      winner_explanation_msg: z.string(),
      winner_msg: z.string(),
    }),
    name: z.string().trim().min(1, { message: "Titre requis" }),
  })

  const form = useCrossbarForm({
    initialValues: initialFormValues(),
    validate: zodResolver(schema),
  })

  const isDirty = useMemo(
    () => !isEqual(crossbar, form.values),
    [crossbar, form]
  )

  return (
    <>
      {isLoading ? (
        <div className="h-100 w-100 d-flex align-items-center justify-content-center">
          <div className="spinner-border" role="status">
            <LoadingOverlay visible={true} overlayOpacity={0} />
          </div>
        </div>
      ) : null}
      <CrossbarFormProvider form={form}>
        <Stack>
          <Group position={"right"}>
            <Button
              className="bg-button-admin disabled:opacity-75"
              disabled={!isDirty}
              onClick={() => {
                if (form.validate().hasErrors) {
                  console.error("form errors", form.validate().errors)
                  return
                }
                save()
              }}
            >
              Sauvegarder
            </Button>
          </Group>
          <Group className="grid  grid-cols-2">
            <AccessRights
              authorizedOrganizations={
                CROSSBAR_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS
              }
              authorizedRoles={
                CROSSBAR_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES
              }
            >
              <Select
                sx={{ flex: 1 }}
                label="Organisme"
                required
                data={ORGANIZATIONS}
                {...form.getInputProps("access.organization")}
              />
            </AccessRights>
            <TextInput
              required
              sx={{ flex: 1 }}
              label={"Titre"}
              {...form.getInputProps("name")}
            />
            <FileInput
              label={"Image de fond"}
              {...form.getInputProps(`images.background`)}
            />
          </Group>

          <Collapsable title="Gardien gauche">
            <Group className="grid grid-rows-5 grid-cols-2">
              <FileInput
                label={"Photo"}
                {...form.getInputProps(`goalkeeper_left.photo`)}
              />
              <FileInput
                label={"Vidéo d'introduction"}
                {...form.getInputProps(`goalkeeper_left.intro`)}
              />
              <FileInput
                label={"But haut gauche"}
                {...form.getInputProps(`goalkeeper_left.goal_upper_left`)}
              />
              <FileInput
                label={"But haut droit"}
                {...form.getInputProps(`goalkeeper_left.goal_upper_right`)}
              />
              <FileInput
                label={"But bas gauche"}
                {...form.getInputProps(`goalkeeper_left.goal_down_left`)}
              />
              <FileInput
                label={"But bas droit"}
                {...form.getInputProps(`goalkeeper_left.goal_down_right`)}
              />
              <FileInput
                label={"Arrêt haut gauche"}
                {...form.getInputProps(`goalkeeper_left.missed_upper_left`)}
              />
              <FileInput
                label={"Arrêt haut droit"}
                {...form.getInputProps(`goalkeeper_left.missed_upper_right`)}
              />
              <FileInput
                label={"Arrêt bas gauche"}
                {...form.getInputProps(`goalkeeper_left.missed_down_left`)}
              />
              <FileInput
                label={"Arrêt bas droit"}
                {...form.getInputProps(`goalkeeper_left.missed_down_right`)}
              />
            </Group>
          </Collapsable>
          <Collapsable title="Gardien droit">
            <Group className="grid grid-rows-5 grid-cols-2">
              <FileInput
                label={"Photo"}
                {...form.getInputProps(`goalkeeper_right.photo`)}
              />
              <FileInput
                label={"Vidéo d'introduction"}
                {...form.getInputProps(`goalkeeper_right.intro`)}
              />
              <FileInput
                label={"But haut gauche"}
                {...form.getInputProps(`goalkeeper_right.goal_upper_left`)}
              />
              <FileInput
                label={"But haut droit"}
                {...form.getInputProps(`goalkeeper_right.goal_upper_right`)}
              />
              <FileInput
                label={"But bas gauche"}
                {...form.getInputProps(`goalkeeper_right.goal_down_left`)}
              />
              <FileInput
                label={"But bas droit"}
                {...form.getInputProps(`goalkeeper_right.goal_down_right`)}
              />
              <FileInput
                label={"Arrêt haut gauche"}
                {...form.getInputProps(`goalkeeper_right.missed_upper_left`)}
              />
              <FileInput
                label={"Arrêt haut droit"}
                {...form.getInputProps(`goalkeeper_right.missed_upper_right`)}
              />
              <FileInput
                label={"Arrêt bas gauche"}
                {...form.getInputProps(`goalkeeper_right.missed_down_left`)}
              />
              <FileInput
                label={"Arrêt bas droit"}
                {...form.getInputProps(`goalkeeper_right.missed_down_right`)}
              />
            </Group>
          </Collapsable>
          <Collapsable title="Textes">
            <TextInput
              required
              sx={{ flex: 1 }}
              label={"Accueil Titre"}
              {...form.getInputProps("messages.welcome_header_msg")}
            />
            <TextInput
              required
              sx={{ flex: 1 }}
              label={"Accueil Contenu"}
              {...form.getInputProps("messages.welcome_content_msg")}
            />
            <TextInput
              required
              sx={{ flex: 1 }}
              label={"Accueil Pied de page"}
              {...form.getInputProps("messages.welcome_footer_msg")}
            />
            <TextInput
              required
              sx={{ flex: 1 }}
              label={"Attente"}
              {...form.getInputProps("messages.waiting_msg")}
            />
            <TextInput
              required
              sx={{ flex: 1 }}
              label={"Choix du gardien"}
              {...form.getInputProps("messages.goalkeeper_choice_msg")}
            />
            <TextInput
              required
              sx={{ flex: 1 }}
              label={"Vainqueur"}
              {...form.getInputProps("messages.winner_msg")}
            />
            <TextInput
              required
              sx={{ flex: 1 }}
              label={"Vainqueur (explications)"}
              {...form.getInputProps("messages.winner_explanation_msg")}
            />
            <TextInput
              required
              sx={{ flex: 1 }}
              label={"Perdant"}
              {...form.getInputProps("messages.looser_msg")}
            />
            <TextInput
              required
              sx={{ flex: 1 }}
              label={"Perdant (explications)"}
              {...form.getInputProps("messages.looser_explanation_msg")}
            />
            <TextInput
              required
              sx={{ flex: 1 }}
              label={"Message lors d'une seconde tentative"}
              {...form.getInputProps("messages.try_again_msg")}
            />
          </Collapsable>
        </Stack>
      </CrossbarFormProvider>
    </>
  )
}
