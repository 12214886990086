import { Routes, Route } from "react-router-dom"

import { CrossbarPresenter } from "crossbar/views/CrossbarPresenter"

export function CrossbarRoutes() {
  return (
    <Routes>
      <Route path=":crossbarEventId">
        <Route path="" element={<CrossbarPresenter />} />
      </Route>
    </Routes>
  )
}
