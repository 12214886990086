import { Flex, Text } from '@mantine/core';
import { ReactElement } from 'react';

import { CROSSBAR_DEFAULT_PLAY } from '_constants';
import { Crossbar } from 'crossbar/types/crossbar.model';
import Btn from '../atoms/Btn';

export interface Props {
  crossbar: Crossbar;
  onPlayClick: () => void;
}

export default function Home({ crossbar, onPlayClick }: Props): ReactElement {
  const {
    messages: { welcome_content_msg, welcome_footer_msg, welcome_header_msg },
  } = crossbar;

  return (
    <Flex
      align="center"
      direction="column"
      justify="space-around"
      mih={400} // To fit entirely on an iPhone 13 Mini
    >
      <Text
        className="text-2xl text-center font-team-a-bold"
        sx={{
          color: "#ffffff",
        }}
        tt="uppercase"
        dangerouslySetInnerHTML={{ __html: welcome_header_msg }}
      />
    
      <Text
        className="text-xl text-center font-team-a-bold"
        sx={{
          color: "#ffffff",
        }}
        dangerouslySetInnerHTML={{ __html: welcome_content_msg }}
      />

      <Text
        className="text-3xl text-center font-team-a-bold"
        sx={{
          color: "#ffffff",
        }}
        tt="uppercase"
        dangerouslySetInnerHTML={{ __html: welcome_footer_msg }}
      />

      <Btn
        label={CROSSBAR_DEFAULT_PLAY}
        onClick={onPlayClick}
      />
    </Flex>
  );
}
