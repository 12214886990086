import { apiSlice } from "core/api"
import { makeCrudEndpoints } from "common/api/apiHelpers"
import { CrossbarEvent } from "crossbar/types/crossbar-event.model"

const api = apiSlice.injectEndpoints({
  endpoints: makeCrudEndpoints<"CrossbarEvent", CrossbarEvent>("CrossbarEvent", "crossbar-events"),
})

export const {
  useGetCrossbarEventQuery,
  useListCrossbarEventsQuery,
  useAddCrossbarEventMutation,
  useRemoveCrossbarEventMutation,
  useUpdateCrossbarEventMutation,
} = api