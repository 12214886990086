export const ADMIN_ROLE = "Admin"
export const ADMIN_ORGANIZATION = "Admin"

export const ORGANIZATIONS = [
  { value: "136", label: "136" },
  { value: "Lille", label: "Lille" },
  { value: "Nanterre", label: "Nanterre" },
  { value: "PSG", label: "PSG" },
  { value: "Reims", label: "Reims" },
]

export const CLICK_AND_COLLECT_AUTHORISED_ORGANIZATIONS = [
  ADMIN_ORGANIZATION,
  "136",
  "PSG",
]
export const CLICK_AND_COLLECT_AUTHORISED_ROLES = [ADMIN_ROLE, "Content"]
export const CLICK_AND_COLLECT_CREATE_AUTHORISED_ORGANIZATIONS = [
  ADMIN_ORGANIZATION,
  "136",
  "PSG",
]
export const CLICK_AND_COLLECT_CREATE_AUTHORISED_ROLES = [ADMIN_ROLE, "Content"]
export const CLICK_AND_COLLECT_DELETE_AUTHORISED_ORGANIZATIONS = [
  ADMIN_ORGANIZATION,
]
export const CLICK_AND_COLLECT_DELETE_AUTHORISED_ROLES = [ADMIN_ROLE]
export const CLICK_AND_COLLECT_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS =
  [ADMIN_ORGANIZATION]
export const CLICK_AND_COLLECT_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES = [
  ADMIN_ROLE,
]
export const CLICK_AND_COLLECT_UPDATE_AUTHORISED_ORGANIZATIONS = [
  ADMIN_ORGANIZATION,
  "136",
  "PSG",
]
export const CLICK_AND_COLLECT_UPDATE_AUTHORISED_ROLES = [ADMIN_ROLE, "Content"]

export const CROSSBAR_AUTHORISED_ORGANIZATIONS = [ADMIN_ORGANIZATION]
export const CROSSBAR_AUTHORISED_ROLES = [ADMIN_ROLE]
export const CROSSBAR_CREATE_AUTHORISED_ORGANIZATIONS = [ADMIN_ORGANIZATION]
export const CROSSBAR_CREATE_AUTHORISED_ROLES = [ADMIN_ROLE]
export const CROSSBAR_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS = [
  ADMIN_ORGANIZATION,
]
export const CROSSBAR_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES = [ADMIN_ROLE]

export const DIAPORAMA_AUTHORISED_ORGANIZATIONS = [
  ADMIN_ORGANIZATION,
  "136",
  "Lille",
  "Nanterre",
  "PSG",
  "Reims",
]
export const DIAPORAMA_AUTHORISED_ROLES = [ADMIN_ROLE, "Content"]
export const DIAPORAMA_CREATE_AUTHORISED_ORGANIZATIONS = [ADMIN_ORGANIZATION]
export const DIAPORAMA_CREATE_AUTHORISED_ROLES = [ADMIN_ROLE]
export const DIAPORAMA_MESSAGES_DELETE_AUTHORISED_ORGANIZATIONS = [
  ADMIN_ORGANIZATION,
]
export const DIAPORAMA_MESSAGES_DELETE_AUTHORISED_ROLES = [ADMIN_ROLE]
export const DIAPORAMA_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS = [
  ADMIN_ORGANIZATION,
]
export const DIAPORAMA_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES = [ADMIN_ROLE]

export const ENTITY_DELETE_AUTHORISED_ORGANIZATIONS = [ADMIN_ORGANIZATION]
export const ENTITY_DELETE_AUTHORISED_ROLES = [ADMIN_ROLE]

export const EVENT_AUTHORISED_ORGANIZATIONS = [ADMIN_ORGANIZATION]
export const EVENT_AUTHORISED_ROLES = [ADMIN_ROLE]
export const EVENT_CREATE_AUTHORISED_ORGANIZATIONS = [ADMIN_ORGANIZATION]
export const EVENT_CREATE_AUTHORISED_ROLES = [ADMIN_ROLE]
export const EVENT_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS = [
  ADMIN_ORGANIZATION,
]
export const EVENT_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES = [ADMIN_ROLE]

export const QUIZ_AUTHORISED_ORGANIZATIONS = [
  ADMIN_ORGANIZATION,
  "136",
  "Lille",
  "Nanterre",
  "PSG",
  "Reims",
]
export const QUIZ_AUTHORISED_ROLES = [ADMIN_ROLE, "Content"]
export const QUIZ_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS = [
  ADMIN_ORGANIZATION,
]
export const QUIZ_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES = [ADMIN_ROLE]

export const WORKFORCE_AUTHORISED_ORGANIZATIONS = [ADMIN_ORGANIZATION]
export const WORKFORCE_AUTHORISED_ROLES = [ADMIN_ROLE]
