import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from "react"
import { isEqual } from "lodash"
import { z } from "zod"
import type { UseFormReturnType } from "@mantine/form"
import { zodResolver } from "mantine-form-zod-resolver"

import type { Crossbar } from "crossbar/types/crossbar.model"
import type { CrossbarEvent } from "crossbar/types/crossbar-event.model"
import type { Event } from "event/types/event.model"
import { Button, Group } from "@mantine/core"
import { DatePicker, TimeInput } from "@mantine/dates"

import {
  useAddCrossbarEventMutation,
  useUpdateCrossbarEventMutation,
} from "crossbar/api/crossbar-event.api"
import { useUpdateEventMutation } from "event/api/event.api"
import { useCrossbarEventForm } from "crossbar/contexts/crossbar-event.context"
import { useDebug } from "common/debug/hooks/useDebug"

export function CrossbarEventForm({
  crossbar,
  crossbarEvent,
  editMode,
  eventForm,
  eventId,
  organization,
  refetchCrossbarEvent,
  setIsLoading,
}: {
  crossbar?: Crossbar
  crossbarEvent?: CrossbarEvent
  editMode: boolean
  eventForm: UseFormReturnType<
    Omit<Event, "id">,
    (values: Omit<Event, "id">) => Omit<Event, "id">
  >
  eventId: string
  organization: string
  refetchCrossbarEvent: () => void
  setIsLoading: Dispatch<SetStateAction<boolean>>
}) {
  const { isDebugMode, log } = useDebug()

  const [addCrossbarEvent, addCrossbarEventResult] =
    useAddCrossbarEventMutation()
  const [updateCrossbarEvent, updateCrossbarEventResult] =
    useUpdateCrossbarEventMutation()

  const [updateEvent, updateEventResult] = useUpdateEventMutation()
  const {
    isError: isErrorAddCrossbarEventResult,
    error: errorAddCrossbarEventResult,
  } = addCrossbarEventResult
  const {
    isError: isErrorUpdateCrossbarEventResult,
    error: errorUpdateCrossbarEventResult,
  } = updateCrossbarEventResult

  const { isError: isErrorUpdateEventResult, error: errorUpdateEventResult } =
    updateEventResult

  const initialFormValues = useCallback(() => {
    if (crossbarEvent && editMode) {
      return {
        crossbar_id: crossbarEvent.crossbar_id,
        end_time: crossbarEvent.end_time,
        entity: crossbarEvent.entity,
        event_date: crossbarEvent.event_date,
        golden_time: crossbarEvent.golden_time,
        organization: crossbarEvent.organization,
        section: crossbarEvent.section,
        start_time: crossbarEvent.start_time,
      }
    }
    const defaultCrossbarEvent = {
      crossbar_id: "",
      end_time: new Date().toISOString(),
      entity: "",
      event_date: new Date().toISOString(),
      golden_time: "",
      organization: organization,
      section: "",
      start_time: new Date().toISOString(),
    }
    if (crossbar && !editMode) {
      defaultCrossbarEvent.crossbar_id = crossbar.id
    }
    return defaultCrossbarEvent
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crossbarEvent])

  const schema = z.object({
    crossbar_id: z.string().min(1, { message: "Crossbar id requis" }),
    end_time: z.string().min(1, { message: "Heure de fin requis" }),
    entity: z.string().optional(),
    event_date: z.string().min(1, { message: "Date de début requise" }),
    golden_time: z.string(),
    organization: z.string().trim().min(1, { message: "Organisation requise" }),
    section: z.string().optional(),
    start_time: z.string().min(1, { message: "Heure de début requis" }),
  })

  const form = useCrossbarEventForm({
    initialValues: initialFormValues(),
    validate: zodResolver(schema),
  })

  const isDirty = useMemo(
    () => !isEqual(crossbarEvent, form.values),
    [crossbarEvent, form]
  )

  useEffect(() => {
    if (isDebugMode) {
      if (isErrorAddCrossbarEventResult) {
        log(
          `errorAddCrossbarEventResult: ${JSON.stringify(
            errorAddCrossbarEventResult
          )}`
        )
      }
      if (isErrorUpdateCrossbarEventResult) {
        log(
          `errorUpdateCrossbarEventResult: ${JSON.stringify(
            errorUpdateCrossbarEventResult
          )}`
        )
      }
      if (isErrorUpdateEventResult) {
        log(`errorUpdateEventResult ${JSON.stringify(errorUpdateEventResult)}`)
      }
    }
  }, [
    isDebugMode,
    log,
    isErrorAddCrossbarEventResult,
    isErrorUpdateCrossbarEventResult,
    errorAddCrossbarEventResult,
    errorUpdateCrossbarEventResult,
    isErrorUpdateEventResult,
    errorUpdateEventResult,
  ])

  const save = async () => {
    setIsLoading(true)
    if (crossbarEvent) {
      try {
        const updateCrossbarEventPayload = updateCrossbarEvent({
          id: crossbarEvent.id,
          ...form.values,
        })
        log(
          `Update crossbar event successful: ${JSON.stringify(
            updateCrossbarEventPayload
          )}`
        )
      } catch (error) {
        log(`Update error: ${error}`)
      }
    } else {
      try {
        const addCrossbarEventPayload = await addCrossbarEvent(
          form.values
        ).unwrap()
        log(
          `Add crossbar event fullfiled: ${JSON.stringify(
            addCrossbarEventPayload
          )}`
        )
        eventForm.setFieldValue("crossbar_event_id", addCrossbarEventPayload.id)
        const updateEventPayload = updateEvent({
          id: eventId as string,
          ...eventForm.values,
        })
        log(`Update event successful: ${JSON.stringify(updateEventPayload)}`)
      } catch (error) {
        log(`Update error: ${error}`)
      }
    }

    setIsLoading(false)
  }

  const launch = async () => {
    setIsLoading(true)
    if (crossbarEvent) {
      try {
        let goldenTime = new Date()
        goldenTime.setMinutes(goldenTime.getMinutes() + 10)
        log(`goldenTime`, goldenTime.toISOString())
        updateCrossbarEvent({
          ...crossbarEvent,
          golden_time: goldenTime.toISOString(),
        })
        form.setFieldValue(
          "golden_time",
          goldenTime.toISOString()
        )
      } catch (error) {
        log(`Launch error ${JSON.stringify(error)}`)
      }
      refetchCrossbarEvent()
    }
    setIsLoading(false)
  }

  // TODO : All of these fields are too coupled, which makes it very error prone.
  // They need to be replaced by https://mantine.dev/dates/date-time-picker to set the date and time together.
  // But before, mantine needs to be upgraded to v7, which is a major upgrade, needing probably breaking changes adaptation.
  return (
    <>
      <Group grow className="mb-5 mt-5">
        <DatePicker
          required
          label={"Date"}
          {...form.getInputProps("event_date")}
          value={new Date(form.values.event_date)}
          onChange={(value: string | null) => {
            if (!value) return

            const newDate = new Date(value)
            const eventDate = new Date(form.values.event_date)

            form.setFieldValue(
              "event_date",
              new Date(
                eventDate.setFullYear(
                  newDate.getFullYear(),
                  newDate.getMonth(),
                  newDate.getDate()
                )
              ).toISOString()
            )

            const { end_time, golden_time, start_time } = form.values;
            const startTime = new Date(start_time);
            form.setFieldValue(
              "start_time",
              new Date(
                eventDate.setHours(startTime.getHours(), startTime.getMinutes())
              ).toISOString()
            )
            const endTime = new Date(end_time);
            form.setFieldValue(
              "end_time",
              new Date(
                eventDate.setHours(endTime.getHours(), endTime.getMinutes())
              ).toISOString()
            )
            if (golden_time) {
              const goldenTime = new Date(golden_time);
              form.setFieldValue(
                "golden_time",
                new Date(
                  eventDate.setHours(goldenTime.getHours(), goldenTime.getMinutes())
                ).toISOString()
              )
            }
          }}
        />
        <TimeInput
          required
          label={"Début"}
          {...form.getInputProps("start_time")}
          value={new Date(form.values.start_time)}
          onChange={(value: string | null) => {
            if (!value) return

            const newDate = new Date(value)
            const eventDate = new Date(form.values.event_date)
            form.setFieldValue(
              "start_time",
              new Date(
                eventDate.setHours(newDate.getHours(), newDate.getMinutes())
              ).toISOString()
            )
          }}
        />
        <TimeInput
          required
          label={"Fin"}
          {...form.getInputProps("end_time")}
          value={new Date(form.values.end_time)}
          onChange={(value: string | null) => {
            if (!value) return

            const newDate = new Date(value)
            const eventDate = new Date(form.values.event_date)
            form.setFieldValue(
              "end_time",
              new Date(
                eventDate.setHours(newDate.getHours(), newDate.getMinutes())
              ).toISOString()
            )
          }}
        />
      </Group>
      {crossbarEvent ? (
        <Group grow className="mb-10 flex">
          <Button
            className="bg-button-admin disabled:opacity-75 mt-6"
            disabled={!!crossbarEvent.golden_time}
            onClick={() => {
              launch()
            }}
          >
            Lancer le crossbar challenge
          </Button>
          {form.values.golden_time ? (
            <TimeInput
              required
              label={"Définie à"}
              {...form.getInputProps("golden_time")}
              value={new Date(form.values.golden_time)}
              onChange={(value: string | null) => {
                if (!value) return
    
                const newDate = new Date(value)
                const eventDate = new Date(form.values.event_date)
                form.setFieldValue(
                  "golden_time",
                  new Date(
                    eventDate.setHours(newDate.getHours(), newDate.getMinutes())
                  ).toISOString()
                )
              }}
            />
          ) : null}
        </Group>
      ) : null}
      <Group position={"right"}>
        <Button
          className="bg-button-admin disabled:opacity-75"
          disabled={!isDirty}
          onClick={() => {
            if (form.validate().hasErrors) {
              log("form errors", form.validate().errors)
              return
            }
            save()
          }}
        >
          Sauvegarder la configuration Crossbar Challenge
        </Button>
      </Group>
    </>
  )
}
