import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CROSSBAR_DEFAULT_TOO_LATE } from '_constants';
import { CrossbarEvent } from 'crossbar/types/crossbar-event.model';
import { Crossbar } from 'crossbar/types/crossbar.model';
import { CrossbarParticipation } from 'crossbar/types/crossbar-participation.model';
import { useSavedEvent } from 'event/hooks/useSavedEvent';
import { PSGHeader } from 'event/play/components/PSGHeader';
import Wait from './atoms/Message';
import GoalkeeperSelector, {
  GoalkeeperSide, Props as GoalkeeperSelectProps,
} from './steps/GoalkeeperSelector';
import Home, { Props as HomeProps } from './steps/Home';
import Shoot, { Props as ShootProps } from './steps/Shoot';

const steps = ['home', 'goalkeeper-selector', 'shoot', 'result'] as const;
type Step = typeof steps[number];

export function CrossbarView({
  crossbar,
  crossbarEvent,
}: {
  crossbar: Crossbar;
  crossbarEvent: CrossbarEvent;
}) {
  const navigate = useNavigate();
  const { eventId } = useParams<{ eventId: string }>();
  const [savedEvent, setSavedEvents] = useSavedEvent(eventId!);

  const [goalkeeperSide, setGoalkeeperSide] = useState<GoalkeeperSide | null>(null);
  const [step, setStep] = useState<Step>('home');
  const [participation] = useState<CrossbarParticipation>({
    crossbarEventId: crossbarEvent.id,
    playerId: savedEvent?.playerId,
    playerPartitionId: savedEvent?.partitionId,
  });
  const [alreadyPlayed] = useState(savedEvent?.crossbarPlayed);

  useEffect(() => {
    if (!savedEvent) {
      navigate(`/event/${eventId}`, { replace: true });
    }
  }, [savedEvent, eventId, navigate]);

  const onPlayClick: HomeProps['onPlayClick'] = () => {
    setStep('goalkeeper-selector')
  };

  const onSelectClick: GoalkeeperSelectProps['onSelectClick'] = (side) => {
    setGoalkeeperSide(side);
    setStep('shoot');
  };

  const onShootDone: ShootProps['onDone'] = () => {
    setSavedEvents({
      ...savedEvent,
      crossbarPlayed: true,
    });
  };

  const endTime = Date.parse(crossbarEvent.end_time);
  const startTime = Date.parse(crossbarEvent.start_time);
  const now = new Date().getTime();
  const hasStarted = now > startTime;
  const hasTerminated = now > endTime;
  const isAvailable = hasStarted && !hasTerminated;
  let notAvailableMessage = '';
  if (!isAvailable) {
    notAvailableMessage = hasTerminated
      ? CROSSBAR_DEFAULT_TOO_LATE
      : crossbar.messages.waiting_msg;
  }

  return (
    <>
      <PSGHeader />

      {alreadyPlayed && <Wait message={crossbar.messages.try_again_msg} />}

      {!alreadyPlayed && (
        <>
          {!isAvailable && <Wait message={notAvailableMessage} />}

          {isAvailable && (
            <>
              {step === 'home' && (
                <Home crossbar={crossbar} onPlayClick={onPlayClick} />
              )}
              {step === 'goalkeeper-selector' && (
                <GoalkeeperSelector crossbar={crossbar} onSelectClick={onSelectClick} />
              )}
              {step === 'shoot' && goalkeeperSide && (
                <Shoot
                  crossbar={crossbar}
                  goalkeeperSide={goalkeeperSide}
                  onDone={onShootDone}
                  participation={participation}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
