import { Route, Routes } from "react-router-dom"

import {
  SCREEN_CAST_PATH,
  SCREEN_CARROUSEL_PATH,
  SCREEN_SHOW_QR_CODE_PATH,
} from "_urls"
import WallQRView from "wall/screen/WallQRView"
import { WallScreenLayout } from "wall/layouts/WallScreenLayout"
import WallCastView from "wall/screen/cast/WallCastView"

export function WallScreenRoutes() {
  return (
    <Routes>
      <Route path=":wallId" element={<WallScreenLayout />}>
        <Route
          path={SCREEN_CAST_PATH}
          element={<WallCastView mode="messages" />}
        />
        <Route
          path={SCREEN_CARROUSEL_PATH}
          element={<WallCastView mode="images" />}
        />
        <Route path={SCREEN_SHOW_QR_CODE_PATH} element={<WallQRView />} />
      </Route>
    </Routes>
  )
}
