export const ADMIN_NAV_CLICK_AND_COLLECT = "Click & Collect"
export const ADMIN_NAV_DIAPORAMAS = "Diaporamas"
export const ADMIN_NAV_EVENTS = "Événements"
export const ADMIN_NAV_CROSSBAR = "Crossbar challenge"
export const ADMIN_NAV_QUIZZES = "Quizzes"
export const ADMIN_NAV_WORKFORCE = "Effectifs"

export const BLINDTEST_WAITING_MSG = "En attente de la prochaine question"
export const BLINDTEST_WAITING_RESULT_MSG = "Calcul du vainqueur en cours"

export const CROSSBAR_DEFAULT_GO_HOME = "Retourner à l'accueil";
export const CROSSBAR_DEFAULT_LOOSER_EXPLANATION_MSG =
  "Vous aurez plus de chances la prochaine fois !"
export const CROSSBAR_DEFAULT_LOOSER_MSG = "C'est perdu !"
export const CROSSBAR_DEFAULT_TRY_AGAIN_MSG =
  "Vous ne pouvez jouer qu'une seule fois. Merci d'avoir participé !"
export const CROSSBAR_DEFAULT_GOALKEEPER_CHOICE_MSG =
  "Quel gardien souhaitez-vous défier ?"
export const CROSSBAR_DEFAULT_PLAY = "Jouer"
export const CROSSBAR_DEFAULT_SELECT = "Sélectionner"
export const CROSSBAR_DEFAULT_SHOOT = "Tirer"
export const CROSSBAR_DEFAULT_TOO_LATE = "C'est terminé !"
export const CROSSBAR_DEFAULT_WAITING_MSG = "Faut attendre !"
export const CROSSBAR_DEFAULT_WELCOME_HEADER_MSG =
  "Bienvenue dans le jeu de la crossbar"
export const CROSSBAR_DEFAULT_WELCOME_CONTENT_MSG =
  "Pour être sélectionné, il faut marquer un pénalty à l'un de nos gardiens."
export const CROSSBAR_DEFAULT_WELCOME_FOOTER_MSG = "Prêt à tenter votre chance ?"
export const CROSSBAR_DEFAULT_WINNER_MSG = "C'est gagné !"
export const CROSSBAR_DEFAULT_WINNER_EXPLANATION_MSG =
  "Vous allez être contacté par l'équipe organisatrice !"

export const CROSSBAR_DEFAULT_BACKGROUND = "/psg/crossbar/images/background.png"

/** GOALKEEPER RIGHT */
export const CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_NAME = 'JANNICK GREEN';
export const CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_PHOTO =
  "/psg/crossbar/images/goalkeeper_right_photo.png"
export const CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_INTRO_VIDEO =
  "/psg/crossbar/videos/goalkeeper_right_intro_video.mp4"
export const CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_GOAL_UPPER_LEFT_VIDEO =
  "/psg/crossbar/videos/goalkeeper_right_goal_upper_left_video.mp4"
export const CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_GOAL_UPPER_RIGHT_VIDEO =
  "/psg/crossbar/videos/goalkeeper_right_goal_upper_right_video.mp4"
export const CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_GOAL_DOWN_LEFT_VIDEO =
  "/psg/crossbar/videos/goalkeeper_right_goal_down_left_video.mp4"
export const CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_GOAL_DOWN_RIGHT_VIDEO =
  "/psg/crossbar/videos/goalkeeper_right_goal_down_right_video.mp4"
export const CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_MISSED_UPPER_LEFT_VIDEO =
  "/psg/crossbar/videos/goalkeeper_right_missed_upper_left_video.mp4"
export const CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_MISSED_UPPER_RIGHT_VIDEO =
  "/psg/crossbar/videos/goalkeeper_right_missed_upper_right_video.mp4"
export const CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_MISSED_DOWN_LEFT_VIDEO =
  "/psg/crossbar/videos/goalkeeper_right_missed_down_left_video.mp4"
export const CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_MISSED_DOWN_RIGHT_VIDEO =
  "/psg/crossbar/videos/goalkeeper_right_missed_down_right_video.mp4"

/** GOALKEEPER LEFT */
export const CROSSBAR_DEFAULT_GOALKEEPER_LEFT_NAME = 'ANDREAS PALICKA';
export const CROSSBAR_DEFAULT_GOALKEEPER_LEFT_PHOTO =
  "/psg/crossbar/images/goalkeeper_left_photo.png"
export const CROSSBAR_DEFAULT_GOALKEEPER_LEFT_INTRO_VIDEO =
  "/psg/crossbar/videos/goalkeeper_left_intro_video.mp4"
export const CROSSBAR_DEFAULT_GOALKEEPER_LEFT_GOAL_UPPER_LEFT_VIDEO =
  "/psg/crossbar/videos/goalkeeper_left_goal_upper_left_video.mp4"
export const CROSSBAR_DEFAULT_GOALKEEPER_LEFT_GOAL_UPPER_RIGHT_VIDEO =
  "/psg/crossbar/videos/goalkeeper_left_goal_upper_right_video.mp4"
export const CROSSBAR_DEFAULT_GOALKEEPER_LEFT_GOAL_DOWN_LEFT_VIDEO =
  "/psg/crossbar/videos/goalkeeper_left_goal_down_left_video.mp4"
export const CROSSBAR_DEFAULT_GOALKEEPER_LEFT_GOAL_DOWN_RIGHT_VIDEO =
  "/psg/crossbar/videos/goalkeeper_left_goal_down_right_video.mp4"
export const CROSSBAR_DEFAULT_GOALKEEPER_LEFT_MISSED_UPPER_LEFT_VIDEO =
  "/psg/crossbar/videos/goalkeeper_left_missed_upper_left_video.mp4"
export const CROSSBAR_DEFAULT_GOALKEEPER_LEFT_MISSED_UPPER_RIGHT_VIDEO =
  "/psg/crossbar/videos/goalkeeper_left_missed_upper_right_video.mp4"
export const CROSSBAR_DEFAULT_GOALKEEPER_LEFT_MISSED_DOWN_LEFT_VIDEO =
  "/psg/crossbar/videos/goalkeeper_left_missed_down_left_video.mp4"
export const CROSSBAR_DEFAULT_GOALKEEPER_LEFT_MISSED_DOWN_RIGHT_VIDEO =
  "/psg/crossbar/videos/goalkeeper_left_missed_down_right_video.mp4"

export const EVENT_CONTACT_FORM_CHECKBOX_LINK = "Disponible ici."
export const EVENT_CONTACT_FORM_CHECKBOX_MESSAGE =
  "J'accepte le règlement du Paris St-Germain"
export const EVENT_CONTACT_FORM_THANKS =
  "Le Paris Saint-Germain Handball <br /> vous remercie pour votre soutien et votre fidélité."
export const EVENT_CONTACT_FORM_VALIDATION_BUTTON = "Valider"
export const EVENT_CONTACT_FORM_WELCOME =
  "Cher supporter bienvenue ! <br/> Pour profiter de l'ensemble de l'expérience, <br/> veuillez remplir le formulaire ci-joint."
export const EVENT_HEADER_FIRST_OPPONENT = "PARIS SAINT-GERMAIN"
export const EVENT_LANDING_MSG =
  "<span>PROFITEZ DE LA FAN EXPÉRIENCE</span><span>DU PARIS SAINT-GERMAIN HANDBALL</span><span>AVANT, PENDANT ET APRÈS LE MATCH !</span>"
export const EVENT_MENU_CLICK_AND_COLLECT = "MENU BUVETTE"
export const EVENT_MENU_KICKOFF = "KICK OFF"
export const EVENT_MENU_MAN_OF_THE_MATCH = "VOTEZ POUR L'HOMME DU MATCH"
export const EVENT_MENU_MESSAGES = "ENCOURAGEZ <br /> VOS <br /> JOUEURS"
export const EVENT_MENU_QUIZ = "JOUEZ <br /> AU QUIZ <br /> MI-TEMPS"
export const EVENT_MENU_QUESTIONS = "QUESTIONS INVITES"
export const EVENT_MENU_PRONOSTICS = "FAITES VOS PRONOSTICS"

export const FORM_VALIDATION_FIRST_NAME_REQUIRED = "Prénom requis."
export const FORM_VALIDATION_LAST_NAME_REQUIRED = "Nom requis."
export const FORM_VALIDATION_EMAIL_NOT_VALIDATED = "Email invalide."
export const FORM_VALIDATION_PHONE_NOT_VALIDATED =
  "Numéro de téléphone valide requis."
export const FORM_VALIDATION_TERMS_OF_SERVICE =
  "Vous devez accepter le règlement général pour continuer."

export const MAN_OF_THE_MATCH_ALREADY_VOTED =
  "Merci d'avoir voté ! Revenez une fois le vote terminé pour voir le résultat."
export const MAN_OF_THE_MATCH_IN_PROGESS = "Désignez votre homme du match !"
export const MAN_OF_THE_MATCH_NOT_STARTED =
  "Il n'est pas encore possible de voter pour l'homme du match."

export const NAV_CLICK_AND_COLLECT = "Menu buvette"
export const NAV_CROSSBAR = "Crossbar challenge"
export const NAV_MAN_MATCH = "Homme du match"
export const NAV_PRONOS = "Pronos"
export const NAV_QUESTIONS = "Questions"
export const NAV_QUIZ = "Quiz mi-temps"
export const NAV_SELFIES_MSG = "Selfies et messages"
export const NAV_TITLE = "Menu"

export const QUIZ_ADMIN_MISSING_WINNER_TEMPLATE =
  "Chaîne de caractère #WINNER# manquante"
export const QUIZ_ADMIN_WRONG_QUESTION_FORMAT =
  "Réponse invalide, doit être un nombre entier."

export const QUIZ_GAME_DEFAULT_ENDING_MOBILE_MSG =
  "Le quiz est terminé, merci d'avoir participé !"
export const QUIZ_GAME_DEFAULT_WINNER_PRIVATE_MSG =
  "Félicitations ! Vous allez être contacté par l'équipe organisatrice"
export const QUIZ_GAME_DEFAULT_ENDING_SCREEN_MSG =
  "Le quiz est terminé !<br> C'est #WINNER# qui le remporte !"
export const QUIZ_GAME_DEFAULT_WAITING_MOBILE_MSG =
  "Le quiz va bientôt commencer."
export const QUIZ_GAME_SCREEN_DEFAULT_WINNER_MSG =
  "Le vainqueur va être contacté par équipe organisatrice"
export const QUIZ_GAME_NOT_CONNECTED =
  "LE QUIZ EST RÉSERVÉ AUX <br/> SPECTATEURS QUI SONT PRÉSENTS <br/> EN TRIBUNE LE JOUR DE LA RENCONTRE."
export const QUIZ_GAME_OVER =
  "Le quiz est terminé !<br/> En attente des résultats."
export const QUIZ_GAME_RESULT_CALCULATION = "Calcul des résultats en cours"
export const WALL_BUTTON = "Rafraîchir"
export const WALL_WAITING_MESSAGE =
  "L'envoi des messages n'est pas encore ouvert"
export const WALL_QUESTIONS_WAITING_MESSAGE =
  "L'envoi des questions est terminé. Profitez du match !"
