export const ADMIN_PATH="admin"
export const ADMIN_CLICK_AND_COLLECTS_PATH="clickandcollects"
export const ADMIN_CROSSBARS_PATH="crossbars"
export const ADMIN_GAMES_PATH="games"
export const ADMIN_EVENTS_PATH="events"
export const ADMIN_PRONOSTICS_PATH="pronostics"
export const ADMIN_QUIZZES_PATH="quizzes"
export const ADMIN_WALLS_PATH="walls"
export const ADMIN_WORKFORCES_PATH="workforces"
export const CLICK_AND_COLLECT_PATH="clickandcollect"
export const CROSSBAR_PATH="crossbar"
export const EVENT_PATH="event"
export const GAME_PATH="game"
export const LOGIN_PATH="login"
export const MAN_OF_THE_MATCH_PATH="motm"
export const PRONOSTIC_PATH="prono"
export const QUIZ_PATH="quiz"
export const SCREEN_PATH="screen"
export const SCREEN_CARROUSEL_PATH="carrousel"
export const SCREEN_CAST_PATH="cast"
export const SCREEN_SHOW_QR_CODE_PATH="showqr"
export const WALL_PATH="wall"
