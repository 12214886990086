import { useState } from "react"
import { useParams } from "react-router-dom"
import type { UseFormReturnType } from "@mantine/form"

import type { Crossbar } from "crossbar/types/crossbar.model"
import type { Event } from "event/types/event.model"
import { LoadingOverlay } from "@mantine/core"

import { useGetCrossbarEventQuery } from "crossbar/api/crossbar-event.api"
import { useGetCrossbarParticipationWinnerQuery } from "crossbar/api/crossbar-participation.api"
import { CrossbarEventForm } from "./CrossbarEventForm"
import CrossbarWinner from './CrossbarWinner'

export function CrossbarEventPresenter({
  crossbar,
  crossbarEventId,
  eventForm,
  organization,
}: {
  crossbar?: Crossbar
  crossbarEventId?: string
  eventForm: UseFormReturnType<
    Omit<Event, "id">,
    (values: Omit<Event, "id">) => Omit<Event, "id">
  >
  organization: string
}) {
  const { eventId } = useParams<{ eventId: string }>()
  const { data, refetch: refetchCrossbarEvent } = useGetCrossbarEventQuery(
    crossbarEventId!, { skip: !crossbarEventId }
  )
  const { data: winnerData } = useGetCrossbarParticipationWinnerQuery({
    crossbarEventId: crossbarEventId!,
  }, { skip: !crossbarEventId });
  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      {isLoading ? (
        <div className="h-100 w-100 d-flex align-items-center justify-content-center">
          <div className="spinner-border" role="status">
            <LoadingOverlay visible={true} overlayOpacity={0} />
          </div>
        </div>
      ) : null}
      {eventId && data ? (
        <>
          <CrossbarEventForm
            crossbar={crossbar}
            crossbarEvent={data}
            editMode={true}
            eventForm={eventForm}
            eventId={eventId}
            organization={organization}
            refetchCrossbarEvent={refetchCrossbarEvent}
            setIsLoading={setIsLoading}
          />

          <strong>Winner</strong>
          <p>Crossbar event ID : {data.id}</p>
          {winnerData?.winner && <CrossbarWinner winner={winnerData.winner} />}
          {!winnerData?.winner && <span>Pas encore de gagnant</span>}
        </>
      ) : null}
      {eventId && !data ? (
        <CrossbarEventForm
          crossbar={crossbar}
          editMode={false}
          eventForm={eventForm}
          eventId={eventId}
          organization={organization}
          refetchCrossbarEvent={refetchCrossbarEvent}
          setIsLoading={setIsLoading}
        />
      ) : null}
    </>
  )
}
