import { Link } from "react-router-dom"
import { Anchor, Breadcrumbs } from "@mantine/core"

import { ADMIN_NAV_CLICK_AND_COLLECT } from "_constants"

export function ClickAndCollectBreadcrumbs() {
  return (
    <Breadcrumbs separator="→">
      <Anchor component={Link} to={"/admin"}>
        Admin
      </Anchor>

      <span>{ADMIN_NAV_CLICK_AND_COLLECT}</span>
    </Breadcrumbs>
  )
}
