import { useCallback, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Box, Button, Group, Loader, Stack, Table, Title } from "@mantine/core"
import { v4 as uuidv4 } from "uuid"

import {
  ADMIN_ORGANIZATION,
  CROSSBAR_CREATE_AUTHORISED_ORGANIZATIONS,
  CROSSBAR_CREATE_AUTHORISED_ROLES,
  CROSSBAR_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS,
  CROSSBAR_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES,
} from "_roles"
import { useDebug } from "common/debug/hooks/useDebug"
import { AccessRights } from "common/admin/auth/AccessRights"
import { CrossbarBreadcrumbs } from "../components/CrossbarBreadcrumbs"
import { useListCrossbarsQuery } from "../api/crossbar.api"
import { useOrganization } from "common/admin/auth/hooks"

import type { Crossbar } from "../types/crossbar.model"
import { CrossbarAdminRow } from "../components/CrossbarAdminRow"
import { useRemoveCrossbarMutation } from "crossbar/api/crossbar.api"

export function CrossbarAdminListView() {
  const { isDebugMode, log } = useDebug()
  const [organization] = useOrganization()
  const navigate = useNavigate()
  const {
    isLoading,
    isFetching,
    isError,
    error,
    data: crossbars,
  } = useListCrossbarsQuery()

  const [isLoadingFromRow, setIsLoadingFromRow] = useState(false)
  const [removeCrossbar, removeCrossbarResult] = useRemoveCrossbarMutation()

  const {
    isError: isErrorRemoveCrossbarResult,
    error: errorRemoveCrossbarResult,
  } = removeCrossbarResult

  useEffect(() => {
    if (isDebugMode && isErrorRemoveCrossbarResult) {
      log(
        `errorRemoveCrossbarResult: ${JSON.stringify(
          errorRemoveCrossbarResult
        )}`
      )
    }
  }, [isDebugMode, isErrorRemoveCrossbarResult, errorRemoveCrossbarResult, log])

  const ths = (
    <tr>
      <th>Nom</th>
      <AccessRights
        authorizedOrganizations={
          CROSSBAR_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS
        }
        authorizedRoles={CROSSBAR_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES}
      >
        <th>Organization</th>
      </AccessRights>

      <th>Créé le</th>

      <th>Actions</th>
    </tr>
  )

  const remove = async (id:string) => {
    setIsLoadingFromRow(true)
    try {
      const removedCrossbarPayload = removeCrossbar(id)
      log(`Remove successful: ${JSON.stringify(removedCrossbarPayload)}`)
    } catch (error) {
      log(`Remove error: ${error}`)
    }
    setIsLoadingFromRow(false)
    return
  }

  const rows = useCallback(() => {
    let filledRows: JSX.Element[] = []
    if (organization) {
      filledRows =
        crossbars && crossbars.length > 0
          ? crossbars
              .filter(
                (crossbar: Crossbar) =>
                  organization === ADMIN_ORGANIZATION ||
                  crossbar.access.organization === organization
              )
              .map((crossbar: Crossbar) => {
                return (
                  <CrossbarAdminRow
                    key={uuidv4()}
                    crossbar={crossbar}
                    onEdit={() => {
                      navigate(crossbar.id)
                    }}
                    onDelete={()=> remove(crossbar.id)}

                  />
                )
              })
          : []
    }
    return filledRows
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crossbars, organization])

  useEffect(() => {
    if (isError) {
      console.error("Get FoodMenu list error", JSON.stringify(error))
    }
  }, [isError, error])

  return (
    <Box>
      <CrossbarBreadcrumbs />
      <Title order={3} align={"center"} m={"lg"}>
        Liste des Crossbars
      </Title>

      <AccessRights
        authorizedOrganizations={CROSSBAR_CREATE_AUTHORISED_ORGANIZATIONS}
        authorizedRoles={CROSSBAR_CREATE_AUTHORISED_ROLES}
      >
        <Group position={"right"}>
          <Button className="bg-button-admin" component={Link} to="new">
            Ajouter une Crossbar
          </Button>
        </Group>
      </AccessRights>
      {isFetching || isLoading || isLoadingFromRow ? (
        <Stack align={"center"}>
          <Loader />
        </Stack>
      ) : null}
      {!isFetching ? (
        <Table highlightOnHover>
          <thead>{ths}</thead>
          <tbody>{rows()}</tbody>
          <tfoot></tfoot>
        </Table>
      ) : null}
    </Box>
  )
}
