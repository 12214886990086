import { Flex, Text } from '@mantine/core';
import { ReactElement, useState } from 'react';

import {
  CROSSBAR_DEFAULT_GOALKEEPER_LEFT_NAME,
  CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_NAME,
  CROSSBAR_DEFAULT_SELECT,
} from '_constants';
import { Crossbar } from 'crossbar/types/crossbar.model';
import Btn from '../atoms/Btn';
import Goalkeeper from '../atoms/Goalkeeper';

export type GoalkeeperSide = 'left' | 'right';

export interface Props {
  crossbar: Crossbar;
  onSelectClick: (side: GoalkeeperSide) => void;
}

export default function GoalkeeperSelector({ crossbar, onSelectClick }: Props): ReactElement {
  const [side, setSide] = useState<GoalkeeperSide | null>(null);

  const onClick = () => {
    if (!side) {
      return;
    }
    onSelectClick(side);
  };

  const onSelectLeft = () => {
    setSide('left');
  };

  const onSelectRight = () => {
    setSide('right');
  };

  const {
    messages: { goalkeeper_choice_msg },
    goalkeeper_left: { photo: leftPhoto },
    goalkeeper_right: { photo: rightPhoto },
  } = crossbar;

  return (
    <Flex
      align="center"
      direction="column"
    >
      <Text
        className="text-2xl text-center font-team-a-bold"
        sx={{
          color: "#ffffff",
        }}
        tt="uppercase"
        dangerouslySetInnerHTML={{ __html: goalkeeper_choice_msg }}
      />

      <Flex>
        <Goalkeeper
          name={CROSSBAR_DEFAULT_GOALKEEPER_LEFT_NAME}
          onSelect={onSelectLeft}
          photo={leftPhoto}
          selected={side === 'left'}
        />
        <Goalkeeper
          name={CROSSBAR_DEFAULT_GOALKEEPER_RIGHT_NAME}
          onSelect={onSelectRight}
          photo={rightPhoto}
          selected={side === 'right'}
        />
      </Flex>

      <Btn
        disabled={side === null}
        label={CROSSBAR_DEFAULT_SELECT}
        onClick={onClick}
      />
    </Flex>
  );
}
