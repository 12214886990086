import { Link } from "react-router-dom"
import { Card, Group, Stack, Title } from "@mantine/core"
import {
  CalendarEvent,
  DoorExit,
  Frame,
  PlayerPlay,
  QuestionMark,
  Run,
} from "tabler-icons-react"

import {
  ADMIN_NAV_CLICK_AND_COLLECT,
  ADMIN_NAV_DIAPORAMAS,
  ADMIN_NAV_EVENTS,
  ADMIN_NAV_CROSSBAR,
  ADMIN_NAV_QUIZZES,
  ADMIN_NAV_WORKFORCE,
} from "_constants"
import {
  CLICK_AND_COLLECT_AUTHORISED_ORGANIZATIONS,
  CLICK_AND_COLLECT_AUTHORISED_ROLES,
  DIAPORAMA_AUTHORISED_ORGANIZATIONS,
  DIAPORAMA_AUTHORISED_ROLES,
  EVENT_AUTHORISED_ORGANIZATIONS,
  EVENT_AUTHORISED_ROLES,
  CROSSBAR_AUTHORISED_ORGANIZATIONS,
  CROSSBAR_AUTHORISED_ROLES,
  QUIZ_AUTHORISED_ORGANIZATIONS,
  QUIZ_AUTHORISED_ROLES,
  WORKFORCE_AUTHORISED_ORGANIZATIONS,
  WORKFORCE_AUTHORISED_ROLES,
} from "_roles"
import {
  ADMIN_CLICK_AND_COLLECTS_PATH,
  ADMIN_CROSSBARS_PATH,
  ADMIN_EVENTS_PATH,
  ADMIN_QUIZZES_PATH,
  ADMIN_WALLS_PATH,
  ADMIN_WORKFORCES_PATH,
} from "_urls"
import { AccessRights } from "common/admin/auth/AccessRights"
import { useQuizToken, useRole } from "common/admin/auth/hooks"

import foodMenu from "./food-menu.png"

export function AdminMenuView() {
  const [, setQuizToken] = useQuizToken()
  const [, setRole] = useRole()

  return (
    <Stack className="h-full" my="xl">
      <AccessRights
        authorizedOrganizations={QUIZ_AUTHORISED_ORGANIZATIONS}
        authorizedRoles={QUIZ_AUTHORISED_ROLES}
      >
        <Link to={ADMIN_QUIZZES_PATH} className="no-underline">
          <Card style={{ flex: 1 }}>
            <Group spacing="xl">
              <QuestionMark size={50} />
              <Title order={3}>{ADMIN_NAV_QUIZZES}</Title>
            </Group>
          </Card>
        </Link>
      </AccessRights>
      <AccessRights
        authorizedOrganizations={DIAPORAMA_AUTHORISED_ORGANIZATIONS}
        authorizedRoles={DIAPORAMA_AUTHORISED_ROLES}
      >
        <Link to={ADMIN_WALLS_PATH} className="no-underline">
          <Card style={{ flex: 1 }}>
            <Group spacing="xl">
              <PlayerPlay size={50} />
              <Title order={3}>{ADMIN_NAV_DIAPORAMAS}</Title>
            </Group>
          </Card>
        </Link>
      </AccessRights>
      <AccessRights
        authorizedOrganizations={WORKFORCE_AUTHORISED_ORGANIZATIONS}
        authorizedRoles={WORKFORCE_AUTHORISED_ROLES}
      >
        <Link to={ADMIN_WORKFORCES_PATH} className="no-underline">
          <Card style={{ flex: 1 }}>
            <Group spacing="xl">
              <Run size={50} />
              <Title order={3}>{ADMIN_NAV_WORKFORCE}</Title>
            </Group>
          </Card>
        </Link>
      </AccessRights>
      <AccessRights
        authorizedOrganizations={EVENT_AUTHORISED_ORGANIZATIONS}
        authorizedRoles={EVENT_AUTHORISED_ROLES}
      >
        <Link to={ADMIN_EVENTS_PATH} className="no-underline">
          <Card style={{ flex: 1 }}>
            <Group spacing="xl">
              <CalendarEvent size={50} />
              <Title order={3}>{ADMIN_NAV_EVENTS}</Title>
            </Group>
          </Card>
        </Link>
      </AccessRights>
      <AccessRights
        authorizedOrganizations={CLICK_AND_COLLECT_AUTHORISED_ORGANIZATIONS}
        authorizedRoles={CLICK_AND_COLLECT_AUTHORISED_ROLES}
      >
        <Link to={ADMIN_CLICK_AND_COLLECTS_PATH} className="no-underline">
          <Card style={{ flex: 1 }}>
            <Group spacing="xl">
              <img
                src={foodMenu}
                alt={ADMIN_NAV_CLICK_AND_COLLECT}
                style={{ width: 50, height: 50 }}
              />
              <Title order={3}>{ADMIN_NAV_CLICK_AND_COLLECT}</Title>
            </Group>
          </Card>
        </Link>
      </AccessRights>
      <AccessRights
        authorizedOrganizations={CROSSBAR_AUTHORISED_ORGANIZATIONS}
        authorizedRoles={CROSSBAR_AUTHORISED_ROLES}
      >
        <Link to={ADMIN_CROSSBARS_PATH} className="no-underline">
          <Card style={{ flex: 1 }}>
            <Group spacing="xl">
              <Frame size={50} />
              <Title order={3}>{ADMIN_NAV_CROSSBAR}</Title>
            </Group>
          </Card>
        </Link>
      </AccessRights>
      <div
        onClick={() => {
          setQuizToken(null)
          setRole(null)
        }}
        className="cursor-pointer"
      >
        <Card style={{ flex: 1 }}>
          <Group spacing="xl">
            <DoorExit size={50} />
            <Title order={3}>Déconnexion</Title>
          </Group>
        </Card>
      </div>
    </Stack>
  )
}
