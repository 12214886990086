import { useCallback, useMemo } from "react"
import { useParams } from "react-router-dom"
import { Container } from "@mantine/core"
import { useViewportSize } from "@mantine/hooks"

import { useGetCrossbarEventQuery } from "crossbar/api/crossbar-event.api"
import { useGetCrossbarQuery } from "crossbar/api/crossbar.api"
import { PlayLoader } from 'quiz/play/components/PlayLoader'
import { CrossbarView } from "./CrossbarView"

export function CrossbarPresenter() {
  const { crossbarEventId } = useParams<{
    eventId: string
    crossbarEventId: string
  }>()
  const { data: crossbarEvent, isSuccess: isSuccessCrossbarEvent } =
    useGetCrossbarEventQuery(crossbarEventId as string)

  const crossbarId = useMemo(() => crossbarEvent?.crossbar_id, [crossbarEvent])

  const { data: crossbar, isSuccess: isSuccessCrossbar } = useGetCrossbarQuery(
    crossbarId!, { skip: !crossbarId }
  )

  const styles = useCallback(() => {
    return {
      desktopBackground: {
        backgroundImage: `url(${crossbar?.images.background}`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      },
      mobileBackground: {
        backgroundImage: `url(${crossbar?.images.background}`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        maxWidth: 768,
      },
    }
  }, [crossbar])

  const { width } = useViewportSize()
  const isMobile = width <= 768
  const currentStyle = isMobile
    ? styles().mobileBackground
    : styles().desktopBackground

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100dvh",
        maxWidth: "none",
        textAlign: "center",
        ...currentStyle,
      }}
    >
      {isSuccessCrossbar &&
      isSuccessCrossbarEvent &&
      crossbar &&
      crossbarEvent ? (
        <CrossbarView crossbar={crossbar} crossbarEvent={crossbarEvent} />
      ) : <PlayLoader message="" />}
    </Container>
  )
}
