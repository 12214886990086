import { Routes, Route } from "react-router-dom"
import { CrossbarAdminListView } from "../views/CrossbarAdminListView"
import { CrossbarAdminView } from "crossbar/views/CrossbarAdminView"
export function CrossbarAdminRoutes() {
  return (
    <Routes>
      <Route path="" element={<CrossbarAdminListView />} />
      <Route
        path="new"
        element={<CrossbarAdminView isCreateMode={true} />}
      />
      <Route path=":crossbarId">
        <Route
          path=""
          element={<CrossbarAdminView isCreateMode={false} />}
        />
      </Route>
    </Routes>
  )
}
