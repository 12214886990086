import { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { SerializedError } from "@reduxjs/toolkit"
import { Alert, LoadingOverlay } from "@mantine/core"
import React, { ReactNode } from "react"
import { isFunction } from "lodash"

type CommonProps = {
  message?: string
  className?: string
  dimOnRefetch?: boolean
}

type Query<T> = {
  isLoading: boolean
  isFetching?: boolean
  isSuccess: boolean
  isError: boolean
  isUninitialized: boolean
  error?: FetchBaseQueryError | SerializedError
  data?: T
}

type Props = CommonProps & {
  children: JSX.Element | ((args: any) => ReactNode)
  query?: Query<unknown>
  queries?: Query<unknown>[]
}

export default function QueryWrapper<T>(
  props: CommonProps & {
    query: Query<T>
    children: JSX.Element | ((r: T) => ReactNode)
  }
): JSX.Element
export default function QueryWrapper<T>(
  props: CommonProps & {
    queries: Query<T>[]
    children: JSX.Element | ((resp: T[]) => ReactNode)
  }
): JSX.Element
export default function QueryWrapper<T1, T2>(
  props: CommonProps & {
    queries: [Query<T1>, Query<T2>]
    children: (resp: [T1, T2]) => ReactNode
  }
): JSX.Element
export default function QueryWrapper<T1, T2, T3>(
  props: CommonProps & {
    queries: [Query<T1>, Query<T2>, Query<T3>]
    children: (resp: [T1, T2, T3]) => ReactNode
  }
): JSX.Element
export default function QueryWrapper<T1, T2, T3, T4>(
  props: CommonProps & {
    queries: [Query<T1>, Query<T2>, Query<T3>, Query<T4>]
    children: (resp: [T1, T2, T3, T4]) => ReactNode
  }
): JSX.Element
export default function QueryWrapper<T1, T2, T3, T4, T5>(
  props: CommonProps & {
    queries: [Query<T1>, Query<T2>, Query<T3>, Query<T4>, Query<T5>]
    children: (resp: [T1, T2, T3, T4, T5]) => ReactNode
  }
): JSX.Element

export default function QueryWrapper<T1, T2, T3, T4, T5, T6>(
  props: CommonProps & {
    queries: [Query<T1>, Query<T2>, Query<T3>, Query<T4>, Query<T5>, Query<T6>]
    children: (resp: [T1, T2, T3, T4, T5, T6]) => ReactNode
  }
): JSX.Element

export default function QueryWrapper({
  children,
  query,
  queries,
  message = "Impossible de charger les données",
  className,
  dimOnRefetch = true,
}: Props) {
  const {
    isLoading,
    isFetching,
    isSuccess,
    isError,
    error,
    isUninitialized,
    data,
  } = query
    ? query
    : {
        isLoading: queries?.some((q) => q.isLoading),
        isFetching: queries?.some((q) => q.isFetching),
        isSuccess: queries?.every((q) => q.isSuccess),
        isError: queries?.some((q) => q.isError),
        error: queries?.find((q) => q.isError)?.error,
        isUninitialized: queries?.every(
          (q) => q.isSuccess || q.isUninitialized
        ),
        data: queries?.map((q) => q.data),
      }

  return (
    <React.Fragment>
      {!isSuccess && (isFetching || isLoading) && (
        <div className="h-100 w-100 d-flex align-items-center justify-content-center">
          <div className="spinner-border" role="status">
            <LoadingOverlay visible={true} overlayOpacity={0} />
          </div>
        </div>
      )}
      {isError && (
        <Alert title={message} color="red">
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Alert>
      )}
      {(isSuccess || isUninitialized) && (
        <div
          style={{
            opacity: dimOnRefetch && (isFetching || isLoading) ? 0.5 : 1,
          }}
          className={className}
        >
          {isFunction(children) ? children(data) : children}
        </div>
      )}
    </React.Fragment>
  )
}
