import { Anchor, Breadcrumbs } from "@mantine/core"
import { Link } from "react-router-dom"

import { ADMIN_NAV_QUIZZES } from "_constants"
import type { Quiz } from "quiz/types/quiz.model"
import type { Game } from "quiz/types/game.model"

export function QuizBreadcrumbs({
  quiz,
  game,
}: {
  quiz?: Quiz | "new"
  game?: Game
}) {
  return (
    <Breadcrumbs separator="→">
      <Anchor component={Link} to={"/admin"}>
        Admin
      </Anchor>
      {quiz ? (
        <Anchor component={Link} to={"/admin/quizzes"}>
          {ADMIN_NAV_QUIZZES}
        </Anchor>
      ) : (
        <span>{ADMIN_NAV_QUIZZES}</span>
      )}
      {quiz === "new" && <span>Nouveau quiz</span>}
      {quiz && quiz !== "new" && !game && (
        <span>{quiz.name || `Quiz #${quiz.id.split("-")[0]}`}</span>
      )}
      {quiz && quiz !== "new" && game && (
        <Anchor component={Link} to={"/admin/quizzes/" + quiz.id}>
          {quiz.name || `Quiz #${quiz.id.split("-")[0]}`}
        </Anchor>
      )}
      {game && <span>{game!.name || `Partie #${game!.id.split("-")[0]}`}</span>}
    </Breadcrumbs>
  )
}
