import { Text } from '@mantine/core';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CROSSBAR_DEFAULT_GO_HOME, CROSSBAR_DEFAULT_SHOOT } from '_constants';
import { useAddCrossbarParticipationMutation } from 'crossbar/api/crossbar-participation.api';
import { Crossbar } from 'crossbar/types/crossbar.model';
import { CrossbarParticipation } from 'crossbar/types/crossbar-participation.model';
import Btn from '../atoms/Btn';
import { GoalkeeperSide } from './GoalkeeperSelector';
import Result from '../atoms/Result';

export interface Props {
  crossbar: Crossbar;
  goalkeeperSide: GoalkeeperSide;
  onDone: () => void;
  participation: CrossbarParticipation;
}

// For now we set a fixed size to make it easier to compute the overlays position.
// If you change this value, you need to re-compute each zone position below.
// Ideally, we would not set a static video size and compute the zones dynamically, using ratios.
const VIDEO_WIDTH = 346;
const OVERLAY_OPACITY = 0.7;
const OVERLAY_WIDTH = 100;
const RESULT_POSITION = { left: 20, right: 20, top: 300 };

// Using the same keys as Crossbar['goalkeeper_*']['videos'] to make the mapping easier
const OVERLAYS = [
  'down_right',
  'down_left',
  'upper_left',
  'upper_right',
] as const;
type Overlay = typeof OVERLAYS[number];
type OverlayConfig = {
  backgroundColor: string;
  left?: number;
  right?: number;
  top?: number;
}
const OVERLAYS_MAPPING: Record<Overlay, OverlayConfig> = {
  "down_right": { backgroundColor: 'red', top: 160, right: 20 },
  "down_left": { backgroundColor: 'green', left: 20, top: 160 },
  "upper_left": { backgroundColor: 'blue', left: 20, top: 60 },
  "upper_right": { backgroundColor: 'yellow', right: 20, top: 60 },
}

export default function Shoot({ crossbar, participation, goalkeeperSide, onDone }: Props): ReactElement {
  const navigate = useNavigate();
  const { eventId } = useParams<{ eventId: string }>();
  const [addParticipation, addParticipationResult] = useAddCrossbarParticipationMutation();

  const [selectedOverlay, setSelectedOverlay] = useState<Overlay | null>(null);
  const [showOverlays, setShowOverlays] = useState<boolean>(false);
  const [showResult, setShowResult] = useState<boolean>(false);

  const videoRef = useRef<HTMLVideoElement>(null);

  const { videos } = crossbar[`goalkeeper_${goalkeeperSide}`];

  useEffect(() => {
    if (!addParticipationResult.data) {
      return;
    }
    const { status } = addParticipationResult.data;
    const videoElt = videoRef.current;
    if (!videoElt || !selectedOverlay) {
      return;
    }
    videoElt.src = videos[`${status}_${selectedOverlay}`];
    videoElt.play();
  }, [addParticipationResult, selectedOverlay, videos]);

  const status = addParticipationResult.data?.status;

  const onVideoEnded = () => {
    if (status) {
      // Result video => show the result
      setShowResult(true);
      onDone();
      return;
    }

    // Intro video ended => show the overlays
    setShowOverlays(true);
  };

  const onOverlayClick = (overlay: Overlay) => {
    setSelectedOverlay(overlay);
  };

  const onShootClick = async () => {
    const videoElt = videoRef.current;
    if (!videoElt || !selectedOverlay) {
      return;
    }

    setShowOverlays(false);
    addParticipation(participation);
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        <video
          autoPlay={true}
          controls={false}
          disablePictureInPicture={true}
          onEnded={onVideoEnded}
          playsInline={true}
          ref={videoRef}
          src={videos.intro}
          style={{ minWidth: VIDEO_WIDTH, width: VIDEO_WIDTH }}
        />

        {showOverlays && OVERLAYS.map((o) => (
          <div
            key={o}
            onClick={() => onOverlayClick(o)}
            style={{
              ...OVERLAYS_MAPPING[o],
              border: selectedOverlay === o ? '3px solid black' : undefined,
              boxSizing: 'border-box',
              cursor: 'pointer',
              height: OVERLAY_WIDTH,
              opacity: OVERLAY_OPACITY,
              position: 'absolute',
              width: OVERLAY_WIDTH,
            }} />
        ))}

        {showResult && status && (
          <div style={{
            ...RESULT_POSITION,
            background: 'white',
            padding: 10,
            position: 'absolute',
          }}>
            <Result crossbar={crossbar} status={status} />
          </div>
        )}
      </div>

      {showResult && status && (
        <Text
          className="text-xl text-center font-team-a-bold"
          sx={{
            color: "#ffffff",
          }}
          mb={5}
          mt={5}
          tt="uppercase"
          dangerouslySetInnerHTML={{ __html: status === 'goal'
            ? crossbar.messages.winner_explanation_msg
            : crossbar.messages.looser_explanation_msg
          }}
        />
      )}

      {!showResult && (
        <Btn
          disabled={selectedOverlay === null}
          label={CROSSBAR_DEFAULT_SHOOT}
          onClick={onShootClick}
          visible={showOverlays}
        />
      )}

      <Btn
        label={CROSSBAR_DEFAULT_GO_HOME}
        onClick={() => navigate(`/event/${eventId}`, { replace: true })}
        visible={showResult}
      />
    </>
  );
}
