import { apiSlice } from "core/api"
import { makeCrudEndpoints } from "common/api/apiHelpers"
import { Crossbar } from "crossbar/types/crossbar.model"

const api = apiSlice.injectEndpoints({
  endpoints: makeCrudEndpoints<"Crossbar", Crossbar>("Crossbar", "crossbars"),
})

export const {
  useGetCrossbarQuery,
  useListCrossbarsQuery,
  useAddCrossbarMutation,
  useRemoveCrossbarMutation,
  useUpdateCrossbarMutation,
} = api