import { ActionIcon } from "@mantine/core"
import { Edit, Trash } from "tabler-icons-react"

import {
  CROSSBAR_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS,
  CROSSBAR_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES,
} from "_roles"

import { AccessRights } from "common/admin/auth/AccessRights"
import { Crossbar } from "crossbar/types/crossbar.model"

export function CrossbarAdminRow({
  crossbar,
  onEdit,
  onDelete,
}: {
  crossbar: Crossbar
  onEdit: () => void
  onDelete: () => void
}) {
  return (
    <tr>
      <td>{crossbar.name}</td>
      <AccessRights
        authorizedOrganizations={
          CROSSBAR_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS
        }
        authorizedRoles={CROSSBAR_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES}
      >
        <td className="max-w-2">{crossbar.access.organization}</td>
      </AccessRights>
      <td>
        {new Date(Date.parse(crossbar.creationTime)).toLocaleString(undefined, {
          dateStyle: "short",
          timeStyle: "short",
        })}
      </td>
      <td className="min-w-4">
        <div className="flex">
          <ActionIcon onClick={onEdit}>
            <Edit color="blue" size={20} />
          </ActionIcon>
          <ActionIcon onClick={onDelete}>
            <Trash color="red" size={20} />
          </ActionIcon>
        </div>
      </td>
    </tr>
  )
}
