import { Anchor, Breadcrumbs } from "@mantine/core"
import { Link } from "react-router-dom"

import { ADMIN_NAV_WORKFORCE } from "_constants"
import { Workforce } from "./workforce.model"

function WorkforceBreadcrumbs({
  workforce,
}: {
  workforce?: Workforce | "new"
}) {
  return (
    <Breadcrumbs separator="→">
      <Anchor component={Link} to={"/admin"}>
        Admin
      </Anchor>
      {workforce ? (
        <Anchor component={Link} to={"/admin/workforces"}>
          {ADMIN_NAV_WORKFORCE}
        </Anchor>
      ) : (
        <span> {ADMIN_NAV_WORKFORCE}</span>
      )}
      {workforce === "new" && <span>Nouvel effectif</span>}
    </Breadcrumbs>
  )
}

export default WorkforceBreadcrumbs
