import { useGetCrossbarQuery } from "crossbar/api/crossbar.api"
import { useParams } from "react-router-dom"
import { CrossbarBreadcrumbs } from "crossbar/components/CrossbarBreadcrumbs"
import { Title } from "@mantine/core"
import { CrossbarAdminForm } from "crossbar/components/CrossbarAdminForm"

export function CrossbarAdminView({ isCreateMode }: { isCreateMode: boolean }) {
  const { crossbarId } = useParams<{ crossbarId: string }>()
  const { data: crossbar } = useGetCrossbarQuery(crossbarId!)
  return (
    <>
      <CrossbarBreadcrumbs crossbar={crossbar} />
      <Title order={3} align={"center"} m={"lg"}>
        {crossbar && crossbarId ? crossbar.name : null}
        {isCreateMode ? "Nouvelle Crossbar" : null}
      </Title>
      {crossbar ? (
        <CrossbarAdminForm crossbar={crossbar} editMode={true} />
      ) : null}

      {!crossbarId && !crossbar ? <CrossbarAdminForm editMode={false} /> : null}
    </>
  )
}
