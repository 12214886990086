import { Button } from '@mantine/core';
import { ReactElement } from 'react';

interface Props {
  disabled?: boolean;
  label: string;
  onClick: () => void;
  visible?: boolean;
}

export default function Btn({ disabled = false, label, onClick, visible = true }: Props): ReactElement {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      style={{
        backgroundColor: "#e30613",
        borderColor: "white",
        color: "white",
        marginTop: "10px",
        visibility: visible ? 'visible' : 'hidden',
      }}
      tt="uppercase"
    >
      <span dangerouslySetInnerHTML={{ __html: label }} />
    </Button>
  );
}
