import { Text } from '@mantine/core';
import { ReactElement } from 'react';

interface Props {
  message: string;
}

export default function Message({ message }: Props): ReactElement {
  return (
    <Text
      className="text-2xl text-center font-team-a-bold"
      sx={{
        color: "#ffffff",
      }}
      dangerouslySetInnerHTML={{ __html: message }}
      tt="uppercase"
    />
  );
}
