import { apiSlice } from "core/api"
import {
  CrossbarParticipation,
  CrossbarParticipationStatus,
  CrossbarParticipationWinner,
} from "crossbar/types/crossbar-participation.model"

const api = apiSlice.injectEndpoints({
  endpoints: (build) => ({
      addCrossbarParticipation: build.mutation<{ status: CrossbarParticipationStatus }, CrossbarParticipation>({
        query: (body) => ({
          method: "POST",
          url: "crossbar-participations",
          body,
        }),
      }),
      getCrossbarParticipationWinner: build.query<{ winner: CrossbarParticipationWinner | null }, { crossbarEventId: string }>({
        query: ({ crossbarEventId }) => ({
          method: "GET",
          url: `crossbar-participations/${crossbarEventId}/winner`,
        }),
      }),
    }),
})

export const {
  useAddCrossbarParticipationMutation,
  useGetCrossbarParticipationWinnerQuery,
} = api