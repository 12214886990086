import { useMemo } from "react"
import { useLocation } from "react-router-dom"
import { useForm } from "@mantine/form"
import { Button, Center, Container, Image, Stack } from "@mantine/core"
import { zodResolver } from "mantine-form-zod-resolver"
import { z } from "zod"

import { accessRegex, phoneRegex } from "common/form/validation"
import { FormFieldTextInput } from "common/form/FormFieldTextInput"
import type { PlayerFormFields } from "common/models/player.model"
import type { Quiz } from "quiz/types/quiz.model"
import type { Event } from "event/types/event.model"
import { PSGHeader } from "event/play/components/PSGHeader"

export const BlindtestRegisterForm = ({
  onSubmit,
  quiz,
}: {
  onSubmit: (values: Partial<PlayerFormFields>) => void
  quiz: Quiz
}) => {
  const location = useLocation()
  const _state = location.state as { event?: Event } | undefined
  const event = _state?.event
  const hasEvent = !!event
  const initialFormValues: Partial<PlayerFormFields> = {
    email: "", // not used here
    firstName: "",
    lastName: "",
    phoneNumber: "",
    accessNumber: "",
    rankNumber: "",
    seatNumber: "",
  }

  const quizSeatingFieldValidationRules = {
    accessNumber: z
      .string()

      .regex(
        accessRegex,
        `${
          quiz.screenType === "bandeau_horizontal" ? "Tribune" : "Accès"
        } format invalide !`
      )
      .min(1, {
        message: `Champs requis`,
      })
      .max(4, {
        message: `Champ limité à 4 caractères`,
      }),
    rankNumber: z
      .string()
      .regex(accessRegex, "Rang format invalide !")
      .min(1, {
        message: "Rang requis",
      })
      .max(4, { message: "Champ limité à 4 caractères" }),
    seatNumber: z
      .string()
      .regex(
        accessRegex,
        `${
          quiz.screenType === "bandeau_horizontal" ? "Place" : "Siège"
        } format invalide !`
      )
      .min(1, {
        message: `${
          quiz.screenType === "bandeau_horizontal" ? "Place" : "Siège"
        } requis`,
      })
      .max(4, {
        message: `Champ limité à 4 caractères`,
      }),
  }

  const eventFormSchema = quiz.hasSeating
    ? z.object({
        ...quizSeatingFieldValidationRules,
      })
    : z.object({})

  const quizPlayerInfoValidationRules = {
    firstName: z
      .string()
      .trim()
      .min(1, { message: "Prénom requis" })
      .max(20, { message: "Prénom limité à 20 caractères" }),
    lastName: z
      .string()
      .trim()
      .min(1, { message: "Nom requis" })
      .max(20, { message: "Nom limité à 20 caractères" }),
    phoneNumber: z
      .string()
      .trim()
      .min(10, { message: "Numéro de téléphone requis" })
      .max(15, {
        message: "Numéro de téléphone limité à 15 caractères",
      })
      .regex(phoneRegex, "Numéro de téléphone Invalide !"),
  }

  const quizOnlyFormSchema = quiz.hasSeating
    ? z.object({
        ...quizPlayerInfoValidationRules,
        ...quizSeatingFieldValidationRules,
      })
    : z.object({
        ...quizPlayerInfoValidationRules,
      })

  const schema = hasEvent ? eventFormSchema : quizOnlyFormSchema

  const form = useForm({
    initialValues: initialFormValues,
    validate: zodResolver(schema),
  })

  const fontColor = useMemo<string>(
    () => (quiz?.colors?.labels ? quiz.colors.labels : "#FFFFFF"),
    [quiz]
  )

  const handleValidateAndSubmit = () => {
    if (form.validate()) {
      onSubmit(form.values)
    } else {
      console.error(form.validate().errors)
    }
  }

  return (
    <Container
      className="min-h-dvh flex flex-col justify-start items-center gap-x-16"
      pb={"lg"}
    >
      <PSGHeader /> {/** displayed only during an event */}
      <form noValidate onSubmit={handleValidateAndSubmit}>
        <Stack className="pt-2.5 text-white" px={"xl"} align={"center"}>
          <Center>
            <Image
              src={quiz.images.registerLogo}
              width={75}
              alt="Register logo"
            />
          </Center>
          {!hasEvent ? (
            <>
              <FormFieldTextInput
                label="Prénom"
                fontColor={fontColor}
                name="firstName"
                type="string"
                isRequired
                form={form}
              />
              <FormFieldTextInput
                label="Nom"
                fontColor={fontColor}
                name="lastName"
                type="string"
                isRequired
                form={form}
              />
              <FormFieldTextInput
                label="Téléphone"
                fontColor={fontColor}
                name="phoneNumber"
                type="string"
                isRequired
                form={form}
              />
            </>
          ) : null}
          {quiz.hasSeating ? (
            <>
              <FormFieldTextInput
                label={
                  quiz.screenType === "bandeau_horizontal" ? "Tribune" : "Accès"
                }
                fontColor={fontColor}
                name="accessNumber"
                type="string"
                isRequired
                form={form}
              />
              <FormFieldTextInput
                label="Rang"
                fontColor={fontColor}
                name="rankNumber"
                type="string"
                isRequired
                form={form}
              />
              <FormFieldTextInput
                label={
                  quiz.screenType === "bandeau_horizontal" ? "Place" : "Siège"
                }
                fontColor={fontColor}
                name="seatNumber"
                type="string"
                isRequired
                form={form}
              />
            </>
          ) : null}
          <Button
            type="submit"
            radius="xs"
            uppercase
            mx="xl"
            className={`bg-[${quiz.colors.buttonBackground}]`}
            styles={{
              root: {
                borderWidth: "2px",
                borderColor: quiz.colors.buttonBorder,
                color: quiz.colors.buttonText,
                padding: 0,
              },
              inner: {
                backgroundColor: quiz.colors.buttonBackground,
                overflowWrap: "break-word",
                fontSize: "1rem",
                textTransform: "uppercase",
                textAlign: "center",
                padding: "5px",
              },
            }}
          >
            Commencer
          </Button>
        </Stack>
      </form>
    </Container>
  )
}
